<template>
  <button
    :class="variant"
    :disabled="disabled"
    @click="$emit('click')"
    @keypress.enter="$emit('click')"
  >
    <div v-if="icon" class="icon">
      <slot name="icon">
        <div class="plus">
          <svg viewBox="0 0 11.86 11.86" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon_feather-plus" data-name="Icon feather-plus">
              <path
                d="M5.93,1v9.86"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
              <path
                d="M1,5.93h9.86"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </g>
          </svg>
        </div>
      </slot>
    </div>
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  font-size: 1.4rem;
  font-weight: 500;
  background: map-get($map: $colours, $key: purple);
  color: #fff;
  padding: 14px;
  border-radius: 50px;
  border: 2px solid #000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform-style: preserve-3d;
  width: calc(100% - 7px);

  &:disabled {
    background: #aeaeae;
    cursor: not-allowed;
  }

  .icon {
    margin-right: 8px;
    max-width: 24px;
    max-height: 24px;
    overflow: hidden;

    .plus {
      width: 18px;
      height: 18px;
      border: 2px solid #fff;
      background: rgba(#fff, 0.2);
      border-radius: 50%;
      display: grid;
      place-items: center;

      * {
        width: 50%;
        stroke: #fff;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    border-radius: 50px;
    background: #000;
    left: 7px;
    top: 0px;
    width: 100%;
    transform: translateZ(-1px);
    height: calc(100% + 7px);
  }

  &.danger {
    background: map-get($map: $colours, $key: red);
  }

  &.secondary {
    background: #fff;
    color: #000;

    &::before {
      background: map-get($map: $colours, $key: purple);
    }
  }
}
</style>
