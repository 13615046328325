var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-input"
  }, [_c('div', {
    staticClass: "input"
  }, [_c('input', _vm._b({
    ref: "input",
    attrs: {
      "data-placeholder": _vm.$attrs.placeholder ? true : false
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "blur": function ($event) {
        return _vm.$emit('blur');
      },
      "input": function ($event) {
        return _vm.$emit('input', _vm.$refs.input.value);
      }
    }
  }, 'input', _vm.$attrs, false)), _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.label))])]), _vm._t("icon")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }