<template>
  <div :aria-expanded="show" class="form-select" @click="show = !show">
    <div class="input">
      <slot name="selected">
        <input
          ref="input"
          :placeholder="placeholder"
          :value="displayTitle"
          disabled
        />
      </slot>

      <label for="">{{ label }}</label>
    </div>
    <i class="icon">
      <img alt="" src="@/assets/icons/feather-arrow-down.svg" />
    </i>

    <transition mode="out-in" name="fade-in">
      <ul
        v-show="show"
        :aria-expanded="show"
        :style="{ maxHeight }"
        class="select-options"
      >
        <li
          v-for="(option, index) in options"
          :key="index"
          :class="{ active: getOption(option) === value }"
          class="select-option"
          role="listbox"
          tabindex="0"
          @click="setActive(option)"
          @keypress.enter="setActive(option)"
        >
          <span class="checkbox" role="checkbox"></span>
          <slot :option="option">
            <p>
              {{ getTitle(option) }}
            </p>
          </slot>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    value: {
      type: undefined,
    },
    label: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    "value-key": {
      type: String,
      default: "option",
    },
    "title-key": {
      type: String,
      default: "title",
    },
    "max-height": {
      type: String,
      default: "300px",
    },
    placeholder: {
      type: String,
      default: "Select Option",
    },
    "use-title": {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    displayTitle() {
      if (this.useTitle) {
        return this.options.find(
          (option) => this.value === this.getOption(option)
        )?.[this.titleKey];
      } else return this.value;
    },
  },
  methods: {
    setActive(option) {
      this.$emit("input", this.getOption(option));
      this.$emit("change", option);
      setTimeout(this.close, 200);
    },
    close() {
      this.show = false;
    },
    getOption(option) {
      return option[this.valueKey] ?? option;
    },
    getTitle(option) {
      return option[this.titleKey] ?? this.getOption(option);
    },
    async handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.close();
      }
    },
  },
  mounted() {
    window.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style lang="scss" scoped>
.select {
  font-weight: 500;
  position: relative;

  &-options {
    z-index: 99;
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    border: 2px solid black;
    border-radius: 8px;
    padding: 8px 0;
    font-size: 1.4rem;
    background: white;
    width: 100%;
    white-space: nowrap;
    overflow: auto;
  }

  &-option {
    padding: 8px 19px;
    display: grid;
    grid-template-columns: 20px auto;
    grid-gap: 16px;
    cursor: pointer;
    width: 100%;

    .checkbox {
      height: 20px;
      border-radius: 50%;
      border: 2px solid black;
      transition: 0.3s background ease-out;
    }

    &.active {
      .checkbox {
        background: {
          color: map-get($colours, purple);
          image: url(@/assets/icons/tick.svg);
          repeat: no-repeat;
          position: center;
          size: 60%;
        }
      }
    }

    &:hover,
    &:focus {
      background: rgba($color: map-get($colours, purple), $alpha: 0.1);
      outline: 2px solid black;
      border-radius: 8px;
    }
  }
}

.form-select {
  height: 56px;
  min-height: 56px;
  border-radius: 8px;
  border: 2px solid #000000;
  padding-inline: 20px;
  display: grid;
  grid-template-columns: 1fr 26px;
  background: inherit;
  position: relative;
  cursor: pointer;

  .input {
    position: relative;
    cursor: pointer;

    input {
      outline: none;
      border: none;
      height: 100%;
      width: 100%;
      background: none;
      font-size: 1.4rem;
      font-weight: 500;
      z-index: -1;

      & + label {
        position: absolute;
        font-size: 1.4rem;
        transform: translateY(-50%);
        top: 0;
        left: -10px;
        font-size: 1.2rem;
        padding-inline: 4px;
        background: #fff;
        transition: 0.3s all ease;
      }
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}
</style>
