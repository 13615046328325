import toast from "vue-m-message";

import { errorHandler, server } from "@/utils";

export default {
  async get() {
    try {
      const { status, data: response } = await server.get("/auth/user/");

      if (status === 200) {
        return response;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async update(body, message = "Profile update successful") {
    try {
      const { status, data: response } = await server.put("/users/", body);

      if (status === 200) {
        toast.success(message);

        return {
          success: true,
          response
        };
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async changePassword({ oldPassword, newPassword1, newPassword2 }) {
    try {
      const { status, data: response } = await server.post(
        "https://beta-api.fluxapi.co.uk/auth/password/change/",
        {
          old_password: oldPassword,
          new_password1: newPassword1,
          new_password2: newPassword2
        }
      );

      if (status === 200) {
        toast.success(response.detail);

        return {
          success: true,
          response
        };
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async verify({ action = "VERIFY", code }) {
    try {
      const { status, data: response } = await server.post("/users/verify/", {
        action,
        code
      });

      if (status === 200) {
        return {
          success: true,
          response
        };
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async login({ email, password }) {
    try {
      const { status, data: response } = await server.post(`/auth/login/`, {
        email,
        password
      });

      if (status === 200) {
        toast.success("Welcome to Flux");
        return response;
      }
    } catch ({ response }) {
      errorHandler(response);
      throw new Error(response);
    }
  },
  async getSavings() {
    try {
      const { status, data: response } = await server.get("/savings/");

      if (status === 200) {
        return response.results;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async getAccountDetails() {
    try {
      const { status, data: response } = await server.get(
        "/users/bank_accounts/"
      );

      if (status === 200) {
        return response.results;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async signup({ email, password1, country_code = "NG", full_name, phone }) {
    try {
      const { status, data: response } = await server.post(`/auth/register/`, {
        email,
        password1,
        country_code,
        channel: "FLUX_WEB",
        full_name,
        phone
      });

      if (status === 201) {
        toast.success("Welcome to Flux");
        return response;
      }
    } catch ({ response }) {
      errorHandler(response);
      throw new Error(response);
    }
  },
  async verifyPhoneNumber({ phone, action = "SEND", channel = "SMS", otp }) {
    if (channel === "Phone Call") channel = "VOICE";
    if (channel === "WhatsApp") channel = "WHATSAPP";

    if (!["SMS", "WHATSAPP", "VOICE"].includes(channel))
      throw new Error("Invalid Channel Selected");

    if (action === "VERIFY" && !otp) throw new Error("OTP not provided");

    try {
      const { status, data: response } = await server.post(
        `/users/phone_number/verification/`,
        {
          action,
          channel,
          phone,
          otp
        }
      );

      if (status === 200) {
        toast.success(response.msg);
        return response;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async verifyBVN({ bvn, action = "SEND_OTP", channel = "SMS", otp }) {
    if (channel === "Phone Call") channel = "CALL";
    if (channel === "WhatsApp") channel = "WHATSAPP";

    if (!["SMS", "WHATSAPP", "CALL"].includes(channel))
      throw new Error("Invalid Channel Selected");

    if (action === "VERIFY" && !otp) throw new Error("OTP not provided");

    try {
      if (action === "SEND_OTP") {
        await this.confirmValidBVN(bvn);
      }

      const { status, data: response } = await server.post(`/kyc/bvn_otp/`, {
        action,
        channel,
        bvn,
        otp
      });

      if (status === 200) {
        toast.success(response.msg);
        return response;
      }
    } catch (e) {
      if (e.response) {
        errorHandler(e.response);
      } else {
        toast.error(e.message);
        throw e;
      }
    }
  },
  async confirmValidBVN(bvn) {
    const { status, data: response } = await server.post(`/kyc/level_1/`, {
      bvn
    });

    if (status === 200) {
      if (response.success) {
        return response;
      } else {
        throw new Error(response.msg);
      }
    }
  }
};
