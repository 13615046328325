var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "transaction-card"
  }, [_c('div', {
    staticClass: "transaction-details"
  }, [_c('h3', [_vm._v(_vm._s(_vm.action))]), _c('p', [_vm._v(_vm._s(_vm.date))])]), _c('div', {
    staticClass: "transaction-amount"
  }, [_c('h4', [_vm._v(_vm._s(_vm.amount))]), _vm.status ? _c('div', {
    staticClass: "status",
    class: _vm.status
  }) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }