<template>
  <transition name="slide-up">
    <aside v-if="show" id="modal-sheet" ref="modalSheet">
      <div class="modal-sheet-body">
        <div class="indicator"></div>
        <div v-if="title" class="header">
          <h3>{{ title }}</h3>
          <button @click="close">
            <img alt="" src="@/assets/icons/plus.svg" />
          </button>
        </div>
        <slot />
      </div>
    </aside>
  </transition>
</template>

<script>
export default {
  name: "ModalSheet",
  props: {
    title: String,
    "start-open": {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: this.startOpen,
    };
  },
  methods: {
    async open() {
      this.show = true;
      window.addEventListener("keydown", this.handleKeys);
    },
    close() {
      this.show = false;
      window.removeEventListener("keydown", this.handleKeys);
    },
    handleKeys(e) {
      if (e.code === "Escape") this.close();
    },
  },
  mounted() {
    // const width = this.$parent.$el.getBoundingClientRect().width;
    // this.$el.style.width = width + "px";
    // console.log(width);
  },
};
</script>

<style lang="scss" scoped>
#modal-sheet {
  position: fixed;
  top: 0;
  left: 0;
  width: Min(100vw, 419px);
  overflow: hidden;
  height: 100vh;
  z-index: 9999;
  transition: all 0.2s ease-in-out;
  backdrop-filter: blur(3px);
  background: rgba($color: #000000, $alpha: 0.2);
  display: flex;

  .modal-sheet-body {
    margin-top: auto;
    background: white;
    padding: 36px;
    padding-top: 12px;
    width: 100%;
    box-shadow: 6px 0px 20px #00000029;
  }

  .indicator {
    width: 37px;
    height: 5px;
    margin-inline: auto;
    background: #ddd2de 0% 0% no-repeat padding-box;
    border-radius: 3px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block: 18px;

    h3 {
      font-size: 2rem;
    }

    button {
      width: 28px;
      height: 28px;
      background: map-get($colours, purple);
      border-radius: 50%;
      border: 2px solid black;
      display: grid;
      place-items: center;

      img {
        width: 50%;
        filter: brightness(0);
        transform: rotate(45deg);
      }
    }
  }
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
}
</style>
