<template>
  <div id="card-options">
    <ul class="options">
      <li v-for="option in options" :key="option.title" class="option">
        <card-button
          reverse
          v-bind="option"
          @click="option.onTap ? option.onTap() : null"
        >
          <template #icon>
            <img :src="option.src" :alt="option.alt" />
          </template>
        </card-button>
      </li>
    </ul>

    <!-- Modals -->
    <template>
      <!-- Deactivate -->
      <modal
        :show="showModal.deactivate"
        :dismissible="true"
        :backdrop="false"
        animation="scale"
        title="Deactivate card?"
        @close="closeModal('deactivate')"
      >
        <div class="deactivate-card">
          <div class="body">
            Are you sure you want to delete this card forever?
          </div>
          <div class="footer">
            <elevated-button
              :icon="false"
              text="Cancel"
              variant="secondary"
              @click="closeModal('deactivate')"
            ></elevated-button>
            <elevated-button
              :icon="false"
              text="Yes, pause card"
              variant="danger"
              @click="deactivateCard"
            ></elevated-button>
          </div>
        </div>
      </modal>
      <!-- Freeze -->
      <modal
        :show="showModal.freeze"
        :dismissible="true"
        :backdrop="false"
        animation="scale"
        title="Freeze card?"
        @close="closeModal('freeze')"
      >
        <div class="deactivate-card">
          <div class="body">
            Are you sure you want to pause transactions from happening on this
            card?
          </div>
          <div class="footer">
            <elevated-button
              :icon="false"
              text="Cancel"
              variant="secondary"
              @click="closeModal('freeze')"
            ></elevated-button>
            <elevated-button
              :icon="false"
              text="Yes, pause card"
              @click="deactivateCard"
            ></elevated-button>
          </div>
        </div>
      </modal>
    </template>
  </div>
</template>

<script>
import { CardButton, ElevatedButton } from "@/components";
// well I'm not sure why this works but it kinda does, so don't rock the boat
import Modal from "@/components/Modal/index.vue";

export default {
  name: "CardActions",
  props: {
    card_ref: {
      type: [Number, String],
      default: "",
    },
  },
  components: {
    CardButton,
    ElevatedButton,
    Modal,
  },
  data() {
    return {
      options: [
        {
          background: "#47C479",
          title: "Create a new card",
          description: "As they say, the more the merrier.",
          src: require("@/assets/icons/plus.svg"),
          alt: "plus icon",
          onTap: () => {
            this.$router.push({
              name: "CreateCard",
            });
          },
        },
        {
          background: "#4187FF",
          title: "Freeze Card",
          description: "Pause all transactions on card.",
          src: require("@/assets/icons/feather-pause.svg"),
          alt: "pause icon",
          onTap: () => {
            this.showModal.freeze = true;
          },
        },
        {
          background: "#FF5E5E",
          title: "Deactivate card",
          description: "Delete this card forever",
          src: require("@/assets/icons/feather-trash-2.svg"),
          alt: "trash icon",
          onTap: () => {
            this.showModal.deactivate = true;
          },
        },
        {
          background: "#FFCC00",
          title: "Unfreeze card",
          description: "Resume transactions on card.",
          src: require("@/assets/icons/feather-play.svg"),
          alt: "play icon",
        },
      ],
      showModal: {
        deactivate: false,
        freeze: false,
      },
    };
  },
  methods: {
    closeModal(key) {
      this.showModal[key] = false;
    },
    deactivateCard() {
      // this.closeModal("deactivate");
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
#card-options {
  .title {
    font-size: 2.4rem;
    letter-spacing: -1.2px;
  }

  .options {
    margin-top: 28px;
    display: grid;
    grid-gap: 28px;
  }

  .deactivate-card {
    max-width: 419px;
    padding: 36px;
    padding-top: 0;

    .body {
      box-shadow: 8px 8px #222, 8px 8px 0px 2px black;
      border: 2px solid #000000;
      border-radius: 8px;
      padding: 22px 24px;
      margin-bottom: 60px;
    }

    .footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
    }
  }
}
</style>
