<template>
  <transition name="slide-out">
    <aside v-if="show" id="drawer" ref="drawer">
      <div class="drawer-body">
        <div class="header">
          <button @click="close">
            <img alt="" src="@/assets/icons/arrow-left.svg" />
          </button>
          <h3>{{ title }}</h3>
        </div>
        <component :is="component" />
      </div>
    </aside>
  </transition>
</template>

<script>
import { timeout } from "@/utils";
import Vue from "vue";

export default {
  props: {},
  data() {
    return {
      show: false,
      componentName: "add-cash",
      title: "Add Cash",
    };
  },
  computed: {
    component() {
      switch (this.componentName) {
        default:
          return () => import("@/fragments/Drawers/AddCash");
      }
    },
  },
  beforeMount() {
    Vue.prototype.$drawer = this;
  },
  methods: {
    async open({ title, componentName }) {
      if (!title || !componentName) return;
      if (
        this.show &&
        this.title === title &&
        this.componentName === componentName
      )
        return;
      if (this.show) {
        this.show = false;
        await timeout(250);
      }
      this.title = title;
      this.componentName = componentName;
      this.show = true;
      window.addEventListener("keydown", this.handleKeys);
    },
    close() {
      this.show = false;
      window.removeEventListener("keydown", this.handleKeys);
    },
    handleKeys(e) {
      if (e.code === "Escape") this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
#drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  overflow: hidden;
  height: 100vh;
  z-index: 9999;
  transition: all 0.2s ease-in-out;

  .drawer-body {
    background: white;
    width: Min(100vw, 419px);
    padding: 46px 36px;
    box-shadow: 6px 0px 20px #00000029;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 37px;

    h3 {
      font-size: 1.6rem;
    }

    button {
      width: 28px;
      height: 28px;
      background: map-get($colours, purple);
      border-radius: 50%;
      border: 2px solid black;
      display: grid;
      place-items: center;

      img {
        width: 50%;
        filter: brightness(0);
      }
    }
  }
}

.slide-out-enter-active,
.slide-out-leave-active {
}

.slide-out-enter,
.slide-out-leave-to {
  transform: translateX(-100%);
}
</style>
