import { reactive, ref } from "vue";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/vue";
import { ethers } from "ethers";
import abis from "@/constants/abis";

const useTokenBalance = () => {
  const balances = reactive({
    ETH: "0",
    USDT: "0",
  });
  const loading = ref(false);

  const { walletProvider } = useWeb3ModalProvider();
  const { address } = useWeb3ModalAccount();

  async function fetchTokenBalance(tokenAddress = "") {
    loading.value = true;

    try {
      const provider = new ethers.providers.Web3Provider(walletProvider.value);

      if (tokenAddress) {
        const contract = new ethers.Contract(
          tokenAddress,
          abis.erc20,
          provider
        );
        const [rawBalance, decimals] = await Promise.all([
          contract.balanceOf(address.value),
          contract.decimals(),
        ]);
        const tokenBalance = ethers.utils.formatUnits(rawBalance, decimals);

        balances.USDT = tokenBalance.toString();
      } else {
        const rawBalance = await provider.getBalance(address.value);

        balances.ETH = Number(ethers.utils.formatEther(rawBalance)).toFixed(4);
      }
    } finally {
      loading.value = false;
    }
  }

  return {
    balances,
    loading,
    fetchTokenBalance,
  };
};

export default useTokenBalance;
