var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "feature-button",
    attrs: {
      "role": "button",
      "tabindex": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      },
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.$emit('click');
      }
    }
  }, [_c('div', {
    staticClass: "feature-icon"
  }, [_vm._t("icon")], 2), _c('h3', {
    staticClass: "feature-text"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }