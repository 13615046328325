<template>
  <container id="app" :class="{ desktop: !isMobile, mobile: isMobile }">
    <header v-if="!isMobile">
      <img id="logo" src="@/assets/icons/flux.svg" alt="flux logo" />
    </header>
    <div id="flux-layout">
      <nav id="side-panel">
        <ul class="nav-list">
          <!-- <router-link
            v-if="isMobile"
            to="/cards"
            custom
            key="cards"
            v-slot="{ navigate, isExactActive, isActive }"
          >
            <li
              class="nav-item"
              data-link="cards"
              :class="{ active: isExactActive || isActive }"
              @click="navigate"
              @keypress.enter="navigate"
              role="link"
            >
              <span class="nav-icon">
                <img
                  src="~flux-pay/assets/icons/metro-credit-card.svg"
                  alt=""
                />
              </span>
              <p class="nav-link">Cards</p>
            </li>
          </router-link> -->

          <router-link
            to="/wallet"
            custom
            key="wallet"
            v-slot="{ navigate, isExactActive }"
          >
            <li
              class="nav-item"
              data-link="wallet"
              :class="{ active: isExactActive || isWallet }"
              @click="navigate"
              @keypress.enter="navigate"
              role="link"
            >
              <span class="nav-icon">
                <img src="@/assets/icons/wallet.svg" alt="" />
              </span>
              <p class="nav-link">Wallet</p>
            </li>
          </router-link>

          <router-link
            to="/crypto"
            custom
            key="crypto"
            v-slot="{ navigate, isExactActive }"
          >
            <li
              class="nav-item"
              data-link="crypto"
              :class="{ active: isExactActive || isCrypto }"
              @click="navigate"
              @keypress.enter="navigate"
            >
              <span class="nav-icon">
                <img src="@/assets/icons/crypto.svg" alt="" />
              </span>
              <p class="nav-link">Crypto</p>
            </li>
          </router-link>
          <router-link
            to="/web3-wallet"
            custom
            key="web3-wallet"
            v-slot="{ navigate, isExactActive }"
          >
            <li
              class="nav-item"
              data-link="web3-wallet"
              :class="{ active: isExactActive || isWeb3 }"
              @click="navigate"
              @keypress.enter="navigate"
            >
              <span class="nav-icon">
                <img src="@/assets/icons/crypto.svg" alt="" />
              </span>
              <p class="nav-link">Web3</p>
            </li>
          </router-link>
          <!--
          <li
            v-if="!isMobile"
            class="nav-item"
            data-link="contact"
            @click="openIntercom"
            @keypress.enter="openIntercom"
            role="link"
          >
            <span class="nav-icon">
              <img src="~flux-pay/assets/icons/contact.svg" alt="" />
            </span>
            <p class="nav-link">
              Need help? <br />
              <span class="subtitle"> Contact Support </span>
            </p>
          </li> -->

          <li
            v-if="!isMobile"
            class="nav-item"
            data-link="signout"
            role="option"
            @click="logout"
          >
            <span class="nav-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-5 -3 24 24"
                width="24"
                fill="currentColor"
              >
                <path
                  d="M3.414 7.828h5.642a1 1 0 1 1 0 2H3.414l1.122 1.122a1 1 0 1 1-1.415 1.414L.293 9.536a.997.997 0 0 1 0-1.415L3.12 5.293a1 1 0 0 1 1.415 1.414L3.414 7.828zM13 0a1 1 0 0 1 1 1v16a1 1 0 0 1-2 0V1a1 1 0 0 1 1-1z"
                ></path>
              </svg>
            </span>
            <p class="nav-link">Sign Out</p>
          </li>
        </ul>
      </nav>
      <div id="main-panel">
        <router-view />
      </div>
    </div>
    <!-- <drawer /> -->

    <modal
      :show="showModal"
      :dismissible="true"
      :backdrop="false"
      animation="scale"
      title="Email Verification"
      @close="showModal = false"
    >
      <div class="email-verification-popup">
        <div class="body">
          Your email has not been verified. Please verify your email to access
          all the features of Flux.
        </div>
        <div class="footer">
          <elevated-button
            :icon="false"
            text="I have verified my email."
            @click="showModal = false"
          ></elevated-button>
        </div>
      </div>
    </modal>
  </container>
</template>

<script>
import { mapGetters } from "vuex";
import { ElevatedButton, Modal } from "@/components";

export default {
  components: {
    Modal,
    ElevatedButton,
  },
  data() {
    return {
      intercomButton: null,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(["isMobile"]),
    isWallet() {
      return this.$route.path.includes("/wallet/");
    },
    isCrypto() {
      return this.$route.path.includes("/crypto/");
    },
    isWeb3() {
      return this.$route.path.includes("/web3-wallet/");
    },
    contactButton() {
      return this.$el.querySelector('.nav-item[data-link="contact"] .nav-icon');
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("user/logout");
    },
    openIntercom() {
      if (!this.intercomButton) return;
      this.intercomButton.click();
    },
    mountIntercom() {
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "brmk0vm6",
        alignment: "left",
      };

      // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/brmk0vm6'

      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
        this.intercomButton = document.querySelector(".intercom-launcher");
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/brmk0vm6";
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };

        l();

        const timer = setInterval(() => {
          if (this.intercomButton) {
            clearInterval(timer);
            // this.intercomButton.style.display = "none";
            // const rect = this.contactButton.getBoundingClientRect();
            // this.intercomButton.style.left = rect.x;
            // this.intercomButton.style.top = rect.y;
            return;
          }
          this.intercomButton = document.querySelector(".intercom-launcher");
        });
      }
    },
  },
  mounted() {
    this.mountIntercom();
    this.showModal = !this.$store.getters["user/user"].email_verified;
  },
};
</script>

<style lang="scss" scoped>
#app.mobile {
  padding: 0;

  #side-panel {
    position: fixed;
    z-index: 999999999;
    height: 75px;
    background: #fff;
    box-shadow: 0px -2px 2px #00000029;
    bottom: 0;
    left: 0;
    right: 0;

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 30px;

      .nav-item {
        display: grid;
        place-items: center;
        opacity: 0.5;
        transition: 0.3s all ease;

        .nav-icon {
          img {
            height: 20px;
            margin-bottom: 10px;
          }
        }

        .nav-link {
          font-size: 12px;
        }

        &[data-link="crypto"] {
          .nav-icon {
            width: 22px;
            height: 22px;
            border: 1.5px solid black;
            display: grid;
            border-radius: 50%;
            place-items: center;
            margin-bottom: 10px;

            img {
              height: 60%;
              width: auto;
              object-fit: contain;
              margin-bottom: unset;
            }
          }
        }

        &.active {
          opacity: 1;
        }
      }
    }
  }
}

#app.desktop {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  header {
    display: grid;
    place-items: center;
    padding: 0 20px;
    height: 60px;

    #logo {
      height: 30px;
    }
  }

  #flux-layout {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 22px;
    overflow: hidden;
    height: calc(100vh - 70px - 24px);
  }

  #side-panel {
    font-size: 14px;
    height: 100%;

    .nav-list {
      display: flex;
      flex-direction: column;
      height: inherit;
      font-weight: bold;
      color: #2c3e50;

      .nav-item {
        margin: 10px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        transition: 0.3s all ease;

        .nav-icon {
          width: 36px;
          height: 36px;
          border: 2px solid currentColor;
          display: grid;
          border-radius: 50%;
          place-items: center;
          margin-right: 16px;
          transition: 0.3s all ease;

          img,
          svg {
            width: 50%;
          }
        }

        /*
        &[data-link="contact"] {
          margin-top: auto;

          .nav-icon {
            background: #222;
          }

          .subtitle {
            font-size: 1.2rem;
            font-weight: normal;
          }
        } */

        &[data-link="signout"] {
          margin-top: auto;
          color: map-get($map: $colours, $key: red);
        }

        &::before {
          content: "";
          opacity: 0;
          width: 10px;
          height: 10px;
          background-color: map-get($map: $colours, $key: purple);
          border: 2px solid #222;
          border-radius: 50%;
          margin-right: 8px;
          transition: 0.2s all ease-out;
        }

        &.active {
          &::before {
            opacity: 1;
          }

          &[data-link="wallet"] {
            .nav-icon {
              background-color: map-get($map: $colours, $key: yellow);
            }
          }

          &[data-link="crypto"] {
            .nav-icon {
              background-color: map-get($map: $colours, $key: purple);
            }
          }
        }
      }
    }
  }

  #main-panel {
    height: inherit;
  }
}

.email-verification-popup {
  max-width: 419px;
  padding: 36px;
  padding-top: 0;

  .body {
    box-shadow: 8px 8px #222, 8px 8px 0px 2px black;
    border: 2px solid #000000;
    border-radius: 8px;
    padding: 22px 24px;
    margin-bottom: 60px;
  }

  .footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
}
</style>
