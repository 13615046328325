<template>
  <header
    id="profile-bar"
    :class="{ mobile: isMobile, scrolled: scrolled && isMobile }"
  >
    <div v-if="user" class="user">
      <div class="user-avatar" role="button">
        <img :src="user.pic" alt="" @click="toggleDrawer" />
      </div>
      <p class="user-name">
        Hi <strong>{{ user?.full_name?.split(" ")[0] }}</strong>
      </p>
    </div>
    <template v-if="isWeb3">
      <div class="wallet__container">
        <div class="wallet__info--container">
          <span class="wallet__info--network"> {{ network }} </span>
          <span class="wallet__info--address">{{ truncateText(address.address) }}</span>
        </div>
        <button @click="disconnect" v-if="!isFreighterConnected">
          <img
            src="@/assets/icons/bin.svg"
            alt="disconnect wallet"
            title="Disconnect wallet"
          />
        </button>
      </div>
    </template>
    <template v-else>
      <div class="flux">
        <span class="flux-head">Flux ID and Tag</span>
        <div class="flux-details" title="Click to copy to Clipboard">
          <p class="flux-id" role="button" @click="copy(user.flux_id)">
            {{ user.flux_id }}
          </p>
          <span class="separator"></span>
          <p
            v-if="user.flux_tag"
            class="flux-tag"
            role="button"
            @click="copy(user.flux_tag)"
          >
            {{ user.flux_tag }}
          </p>
        </div>
      </div>
    </template>

    <transition v-if="isMobile" name="slide-out">
      <aside v-if="showDrawer" id="drawer" :class="{ mobile: isMobile }">
        <div class="drawer-body">
          <drawer-header defaultAction="close" :onTap="toggleDrawer" />

          <div class="details">
            <div class="user">
              <div class="user-avatar" role="button">
                <img :src="user.pic" alt="" />
              </div>
              <div>
                <p class="user-name">
                  <strong>{{ user.full_name }}</strong>
                </p>

                <div class="flux-details">
                  <p class="flux-id">{{ user.flux_id }}</p>
                  <span class="separator"></span>
                  <p v-if="user.flux_tag" class="flux-tag">
                    {{ user.flux_tag }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <ul class="drawer-actions">
            <li
              v-for="action in actions"
              :key="action.title"
              class="drawer-action"
              role="button"
              @click="action.onTap ? action.onTap() : null"
            >
              <img :src="action.src" :alt="action.alt" />
              <p>{{ action.title }}</p>
            </li>
          </ul>
        </div>
      </aside>
    </transition>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import { computed } from "vue";
import { useDisconnect, useWeb3ModalAccount } from "@web3modal/ethers5/vue";
import { chainIdToNetwork } from "@/constants";
import { truncateText } from "@grandmastr/helpers";
import useFreighterAccount from "@/composables/useFreighterAccount";

export default {
  name: "ProfileBar",
  setup(props) {
    console.log(props, "shout");
    const { address, chainId } = useWeb3ModalAccount();
    const { isConnected: isFreighterConnected, account: stellarAccount } =
      useFreighterAccount();
    const { disconnect } = useDisconnect();

    const network = computed(() =>
      isFreighterConnected
        ? stellarAccount.value?.network
        : chainIdToNetwork(chainId.value)
    );

    return {
      address: isFreighterConnected
        ? stellarAccount
        : { address: address.value },
      network,
      disconnect,
      isFreighterConnected,
      stellarAccount,
    };
  },
  data() {
    return {
      scrolled: document.scrollingElement.scrollTop > 10,
      showDrawer: localStorage.getItem("drawer"),
      actions: [
        {
          title: "Beneficiaries",
          src: require("@/assets/icons/feather-people.svg"),
          alt: "people icon",
          onTap: () => {
            this.$router.push({
              name: "Beneficiaries",
            });
          },
        },
        {
          title: "Account Statement",
          src: require("@/assets/icons/feather-document.svg"),
          alt: "document icon",
          onTap: () => {
            this.$router.push({ name: "AccountStatement" });
          },
        },
        {
          title: "Sign Out",
          src: require("@/assets/icons/signout.svg"),
          alt: "logout icon",
          onTap: () => {
            localStorage.removeItem("drawer");
            this.$store.dispatch("user/logout");
          },
        },
        // {
        //   title: "Settings",
        //   src: require("flux-pay/assets/icons/feather-settings.svg"),
        //   alt: "settings icon",
        //   onTap: () => {},
        // },
      ],
    };
  },
  props: {
    isWeb3: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({ user: "user/user", isMobile: "isMobile" }),
  },
  mounted() {
    this.$store.dispatch("user/getUser");
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("keydown", this.handleKeys);
  },
  methods: {
    truncateText,
    handleScroll() {
      this.scrolled = document.scrollingElement.scrollTop > 10;
    },
    handleKeys(e) {
      if (e.code === "Escape") this.toggleDrawer();
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
      if (this.showDrawer) localStorage.setItem("drawer", this.showDrawer);
      else localStorage.removeItem("drawer");
    },
    copy(input) {
      navigator.clipboard.writeText(input);
      this.$toast.success("Copied to Clipboard");
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("keydown", this.handleKeys);
  },
};
</script>

<style lang="scss" scoped>
#profile-bar {
  /* height: 28px; */
  padding: 14px 28px;
  /* padding-bottom: 20px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: white;
  border-radius: 10px;
  z-index: 999;

  div.wallet__container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  div.wallet__info--container {
    display: flex;
    flex-direction: column;

    span.wallet__info--network {
      font-weight: 400;
      text-align: right;
    }

    span.wallet__info--address {
      color: #929292;
    }
  }

  &.mobile {
    border-radius: 0;
    width: 100vw;

    &.scrolled {
      box-shadow: 0px 2px 2px #00000029;
    }
  }

  .user {
    display: flex;
    align-items: center;

    &-avatar {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      border: 2px solid #000;
      margin-right: 12px;
      overflow: hidden;
    }
  }

  .flux {
    text-align: right;

    &-head {
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: 0px;
      color: #222222;
    }

    &-details {
      display: flex;
      align-items: center;
      font-weight: medium;
      font-size: 1.2rem;

      .separator {
        width: 4px;
        height: 4px;
        background: #000000;
        border-radius: 50%;
        margin: 0 6px;
      }
    }
  }

  .drawer {
    &-body {
      background: white;
      height: 100%;
      width: Min(80vw, 419px);
      padding: 36px;
      box-shadow: 6px 0px 20px #00000029;
      display: flex;
      flex-direction: column;

      .details {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .user {
          &-avatar {
            height: 44px;
            width: 44px;
          }
        }
      }
    }

    &-actions {
      display: grid;
      grid-gap: 20px;
      margin-top: 36px;
    }

    &-action {
      padding: 10px 24px;
      border: 2px solid #000;
      border-radius: 8px;
      display: flex;
      align-items: center;

      img {
        margin-right: 12px;
        height: 20px;
        width: 20px;
        object-fit: contain;
      }

      p {
        font-size: 14px;
      }
    }
  }
}
</style>
