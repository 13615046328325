import toast from "vue-m-message";

import { capitalise } from "@/utils";

export default (response, notifyError = true) => {
  let errorMessage = "";

  if (response) {
    const data = response.data ?? response;

    if (typeof data !== "object") {
      errorMessage = "An error occurred, please try again";
    } else {
      for (const error of Object.values(data)) {
        errorMessage = `${errorMessage}${
          // eslint-disable-next-line no-void
          error !== null || error !== void 0 ? `${error}` : ""
        }`;
      }
    }

    if (notifyError) toast.error(capitalise(errorMessage));

    throw Error(errorMessage);
  }
};
