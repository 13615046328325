export default {
  namespaced: true,
  state: () => ({
    plans: [
      {
        id: 1,
        maturityDate: "20 Oct, 2021",
        recurringPrinciple: {
          amount: "49,335",
          currency: "₦",
          duration: "m",
        },
        savedAmount: {
          amount: "567.56",
          currency: "€",
        },
        description: "Save like there’s no tomorrow",
        imageUrl: require("@/assets/images/currencies/EUR.png"),
        status: "running",
      },
      {
        id: 2,
        maturityDate: "12 Oct, 2021",
        recurringPrinciple: {
          amount: "9000",
          currency: "₦",
          duration: "m",
        },
        savedAmount: {
          amount: "2000.00",
          currency: "$",
        },
        description: "Dollar lanje",
        imageUrl: require("@/assets/images/currencies/USD.png"),
        status: "completed",
      },
      {
        id: 3,
        maturityDate: "12 Oct, 2021",
        recurringPrinciple: {
          amount: "400",
          currency: "₦",
          duration: "w",
        },
        savedAmount: {
          amount: "0.18",
          currency: "€",
        },
        description: "Save like there’s no tomorrow",
        imageUrl: require("@/assets/images/currencies/EUR.png"),
        status: "paused",
      },
    ],
    activePlan: null,
  }),
  mutations: {
    SET_PLAN(state, plan) {
      state.activePlan = plan;
    },
  },
  actions: {
    fetchPlan({ state }, id) {
      return state.plans.find((plan) => plan.id === id);
    },
  },
  getters: {
    plans(state) {
      return state.plans;
    },
    activePlan(state) {
      return state.activePlan;
    },
  },
};
