var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "beneficiary"
  }, [_c('div', {
    staticClass: "beneficiary-image"
  }, [_vm.avatar ? _c('img', {
    attrs: {
      "src": _vm.avatar,
      "alt": ""
    }
  }) : _c('p', [_vm._v(_vm._s(_vm.name.charAt(0).toUpperCase()))])]), _c('h4', {
    staticClass: "beneficiary-name"
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")]), _c('p', {
    staticClass: "beneficiary-address"
  }, [_vm._v(_vm._s(_vm.address))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }