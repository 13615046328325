<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.card {
  padding: 25px 32px;
  box-shadow: 7px 7px #fff, 7px 7px 0px 2px black;
  border: 2px solid #000000;
  border-radius: 8px;
  transition: 0.3s all ease-out;
}
</style>
