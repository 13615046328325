import { errorHandler, server } from "@/utils";
import toast from "vue-m-message";

export default {
  async validateFluxTag(tag, { notifyError } = { notifyError: true }) {
    if (tag.startsWith("@")) {
      tag = tag.substring(1);
    }
    try {
      const query = /^[Ff]\w{3}.+$/.test(tag)
        ? `flux_id=${tag}`
        : `flux_tag=@${tag}`;

      const { status, data: response } = await server.get(
        `/users/details/v2/?${query}`
      );

      if (status === 200) {
        return response;
      }
    } catch ({ response }) {
      errorHandler(response, notifyError);
    }
  },
  async validateCryptoWallet({ asset, network, address }) {
    try {
      const { status, data: response } = await server.post(
        `/crypto/v3/verify_address/`,
        {
          asset,
          network,
          address,
        }
      );

      if (status === 200 && response?.data?.valid) {
        return response.data;
      } else {
        throw Error("Invalid token address.");
      }
    } catch (e) {
      if (e.response) {
        errorHandler(e.response);
      } else {
        toast.error(e.message);
        throw e;
      }
    }
  },
  async validateBankDetails({ account_no, bank_code }) {
    try {
      const { status, data: response } = await server.post(
        `/users/verify/account/`,
        { account_no, bank_code }
      );

      if (status === 200) {
        return response;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async getExchangeRate({
    kind = "BUY",
    send = "NGN",
    receive,
    destination = "FLUX_WALLET",
    amount = 1,
  }) {
    try {
      const { status, data: response } = await server.get(
        `/exchange/crypto/rate/`,
        {
          params: {
            kind,
            send,
            receive,
            destination,
            amount,
          },
        }
      );

      if (status === 200) {
        return response.data;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async getFXRate({
    kind = "BUY",
    send = "NGN",
    receive,
    destination = "FLUX_WALLET",
    amount = 1,
  }) {
    // return {
    //   rate: 46480,
    // };
    // // eslint-disable-next-line no-unreachable
    try {
      const { status, data: response } = await server.get(
        `/virtual_cards/rates/`,
        {
          params: {
            kind,
            send,
            receive,
            destination,
            amount,
          },
        }
      );

      if (status === 200) {
        return response.data;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
};
