import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

import { User } from "@/api";
import Router from "@/router";

export default {
  namespaced: true,
  state: () => ({
    user: JSON?.parse(localStorage?.getItem("bravo-zulu")) ?? {},
    token: Cookies?.get("bravo-zulu") || "",
    hideBalance: true,
    accounts: [],
    savings: [],
    signUpDetails: {
      fullname: null,
      username: null,
      email: null,
      phone: null,
      password1: null,
      verificationMethod: "SMS",
      countryCode: "NG",
      bvn: null,
    },
  }),
  getters: {
    user(state) {
      return state.user;
    },
    token(state) {
      return state.token;
    },
    signUpDetails(state) {
      return state.signUpDetails;
    },
    hideBalance(state) {
      return state.hideBalance;
    },
    savings(state) {
      return state.savings;
    },
    accounts(state) {
      return state.accounts;
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setHideBalance(state, hide) {
      state.hideBalance = hide;
    },
    SET_SAVINGS(state, savings) {
      state.savings = savings;
    },
    SET_ACCOUNTS(state, accounts) {
      state.accounts = accounts;
    },
    SET_SIGNUP_DETAILS(state, { key, value }) {
      state.signUpDetails[key] = value;
    },
    CLEAR_SIGNUP_DETAILS(state) {
      state.signUpDetails = {
        fullname: null,
        username: null,
        email: null,
        phone: null,
        verificationMethod: "SMS",
        countryCode: "NG",
        password1: null,
      };
    },
    CLEAR_AUTH(state) {
      Cookies?.remove("bravo-zulu");
      localStorage.removeItem("bravo-zulu");

      state.user = "";
      state.token = "";
    },
  },
  actions: {
    async syncToken({ commit }, token) {
      const data = await CryptoJS?.TripleDES.encrypt(
        token,
        "pluginUpdatesAvailable"
      ).toString();

      Cookies.set("bravo-zulu", data, {
        expires: process.env.NODE_ENV === "development" ? 7 : 1,
        sameSite: "Lax",
      });
      commit("setToken", token);
    },
    async syncUserData({ commit }, user) {
      if (user === undefined) return;
      localStorage.setItem("bravo-zulu", JSON.stringify(user));
      commit("setUser", user);
    },
    async updateUser({ commit }, body) {
      const data = await User.update(body);

      if (data?.success) {
        localStorage.setItem("bravo-zulu", JSON.stringify(data.response));
        commit("setUser", data.response);

        return data.response;
      }
    },
    async logout({ commit }) {
      commit("CLEAR_AUTH");

      Router.push({ name: "Login" });
    },
    async login({ state, dispatch }, body) {
      const response = await User.login(body);
      dispatch("syncUserData", response.user);
      dispatch("syncToken", response.token);

      if (!state.user.phone_verified) {
        throw new Error("PHONE_NOT_VERIFIED");
      }

      if (!state.user.bvn_verified) {
        throw new Error("BVN_NOT_VERIFIED");
      }

      await dispatch("setBalance");
    },
    async getUser({ dispatch }) {
      try {
        const response = await User.get();
        dispatch("syncUserData", response);
        await dispatch("setBalance");
      } catch (err) {
        // console.log(err);
      }
    },
    async setBalance({ commit }) {
      const savings = await User.getSavings();
      commit("SET_SAVINGS", savings);
    },
    async getBankDetails({ commit }) {
      const accounts = await User.getAccountDetails();
      commit("SET_ACCOUNTS", accounts);
    },
    async signup({ dispatch }, body) {
      try {
        const response = await User.signup(body);
        dispatch("syncUserData", response.user);
        dispatch("syncToken", response.token);
        await dispatch("updateUser", { username: body.username });
        await dispatch("setBalance");
        return response.user;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
