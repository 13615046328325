var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "portfolio-card",
    attrs: {
      "role": "button",
      "data-disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      },
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.$emit('click');
      }
    }
  }, [_c('div', {
    staticClass: "portfolio-image"
  }, [_c('crypto-img', {
    attrs: {
      "asset": _vm.asset
    }
  })], 1), _c('div', {
    staticClass: "portfolio-details"
  }, [_c('h3', [_vm._v(_vm._s(_vm.asset))]), _c('p', [_vm._v(_vm._s(_vm.showAmount ? _vm.balance : "") + " " + _vm._s(_vm.asset) + " (" + _vm._s(_vm.network) + ")")])]), _vm.showAmount ? _c('div', {
    staticClass: "portfolio-amount"
  }, [_c('h4', [[_vm._v(" " + _vm._s(_vm.toCurrency(_vm.converted_balance || 0, "USD")) + " ")]], 2), _vm.growth ? _c('p', {
    staticClass: "growth",
    class: _vm.status
  }, [_vm._v(" " + _vm._s(_vm.growth) + " ")]) : _vm._e()]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }