var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    staticClass: "balance"
  }, [_c('div', {
    staticClass: "heading"
  }, [_c('span', [_vm._v("Portfolio Balance")]), _vm.fetching ? _c('skeleton-box') : _vm._e()], 1), _c('div', {
    staticClass: "balance-available"
  }, [_vm.fetching ? _c('skeleton-box', {
    attrs: {
      "height": "25px"
    }
  }) : _c('h2', [!_vm.hidden ? [_vm.isWeb3 ? [_c('span', [_vm._v(_vm._s(_vm.cryptoBalance) + " " + _vm._s(_vm.isFreighterConnected ? "XLM" : "ETH"))]), _c('span', [_vm._v(" ($" + _vm._s((_vm.cryptoBalance * _vm.rates[_vm.isFreighterConnected ? "XLM" : "ETH"] * 0.01).toFixed(4)) + ")")])] : [_vm._v(" " + _vm._s(_vm.toCurrency(_vm.filteredTokens.reduce(function (partialSum, a) {
    return partialSum + (a.converted_balance || 0);
  }, 0), "USD")) + " ")]] : [_vm._v(" ******"), _c('span', [_vm._v(".**")])]], 2), _c('button', {
    staticClass: "icon",
    on: {
      "click": function ($event) {
        _vm.hidden = !_vm.hidden;
      }
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.hidden ? _c('img', {
    key: _vm.hidden.toString(),
    attrs: {
      "src": require("@/assets/icons/eye-open.svg"),
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/icons/eye-closed.svg"),
      "alt": ""
    }
  })])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }