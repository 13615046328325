export default {
  bind(el, binding) {
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      el.src = reader.result;
    });

    // Read the data for the file in through the reader. When it has been loaded, we listen to the event propagated and set the image src to what was loaded from the reader.

    reader.readAsDataURL(binding.value);
  },
};
