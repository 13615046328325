import { errorHandler, server } from "@/utils";
import toast from "vue-m-message";

export default {
  async get() {
    try {
      const { status, data: response } = await server.get("/transactions/v2/");

      if (status === 200) {
        return response.results;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async sendMoneyToFlux({ amount, receiver, pin }) {
    try {
      const { status, data: response } = await server.post(
        "/transactions/v2/",
        {
          amount,
          receiver,
          pin,
        }
      );

      if (status === 200) {
        toast.success("Successfully Transfered Money to Flux User");
        return response;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async sendMoneyToBank({ bank_code, acct_no, amount, acct_name }) {
    try {
      const { status, data: response } = await server.post(
        "/wallet/v2/withdraw/",
        {
          bank_code,
          acct_no,
          amount,
          acct_name,
        }
      );

      if (status === 200) {
        toast.success("Successfully Transfered Money to Bank Account");
        return response;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async getAccouontStatement({
    start_date,
    end_date,
    statement_type = "BANK_STATEMENT",
  }) {
    try {
      const { status, data: response } = await server.post(
        "/transactions/account_statement/",
        {
          start_date,
          end_date,
          statement_type,
        }
      );

      if (status === 200) {
        toast.success(response.msg);
        return response;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
};
