<template>
  <li
    class="portfolio-card"
    role="button"
    :data-disabled="disabled"
    @click="onClick"
    @keypress.enter="onClick"
  >
    <div class="portfolio-image">
      <crypto-img :asset="asset" />
    </div>
    <div class="portfolio-details">
      <h3>{{ asset }}</h3>
      <p>
        {{ showAmount ? toCurrency(balance, null, false) : "" }}
        {{ asset }} ({{ network }})
      </p>
    </div>
    <div v-if="showAmount" class="portfolio-amount">
      <h4>
        <template>
          {{ toCurrency(Number(balance).toFixed(4) || 0, null, false) }}
        </template>
        <!--        <template v-else> 0</template>-->
      </h4>
      <p v-if="growth" class="growth" :class="status">
        {{ growth }}
      </p>
    </div>
  </li>
</template>

<script>
import { Polarity } from "@/utils";
import toCurrency from "../../../plugins/filters/toCurrency";
import { computed, getCurrentInstance } from "vue";
import useFreighterAccount from "@/composables/useFreighterAccount";

export default {
  methods: {
    toCurrency,
  },
  setup(props) {
    const context = getCurrentInstance();
    const { isConnected: isFreighterConnected } = useFreighterAccount();

    const connectedWallet = computed(() =>
      isFreighterConnected ? "Freighter" : "Web3"
    );

    const onClick = async () => {
      if (props.isWeb3) {
        if (
          (connectedWallet.value === "Freighter" && props.network === "XLM") ||
          (connectedWallet.value === "Web3" && props.network === "ERC20")
        ) {
          context.proxy.$emit("click");
        } else {
          context.proxy.$toast.error(
            `Please connect an ${
              connectedWallet.value === "Freighter" ? "ERC20" : "XLM"
            } wallet`
          );
        }
      } else {
        context.proxy.$emit("click");
      }
    };

    return {
      onClick,
    };
  },
  props: {
    growth: {
      type: String,
      validator(value) {
        return value.charAt(0) === "+" || value.charAt(0) === "-";
      },
    },
    asset: {
      type: String,
      required: false,
    },
    balance: {
      type: [String, Number],
      required: false,
    },
    converted_balance: {
      type: undefined,
    },
    network: {
      type: String,
      required: false,
    },
    showAmount: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isWeb3: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      return Polarity.checkPolarity(this.growth);
    },
  },
};
</script>

<style lang="scss" scoped>
.portfolio {
  &-card {
    display: flex;
    align-items: center;
    height: fit-content;

    &[data-disabled="true"] {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &-image {
    width: 32px;
    height: 32px;
    border: 2px solid #000;
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin-right: 14px;

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      border-radius: 50%;
    }
  }

  &-details {
    h3 {
      font-size: 1.4rem;
    }

    p {
      font-size: 1.2rem;
      margin-top: 5px;
    }
  }

  &-amount {
    margin-left: auto;

    h4 {
      font-size: 1.4rem;
      font-weight: bold;
    }

    .growth {
      text-align: right;
      font-size: 1.2rem;
      margin-top: 5px;

      &.positive {
        color: #47c479;
      }

      &.neutral {
        color: #4187ff;
      }

      &.negative {
        color: #ff5e5e;
      }
    }
  }
}
</style>
