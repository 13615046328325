var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "transaction-history"
  }, [_c('section-header', {
    attrs: {
      "title": "Transaction History"
    }
  }), !_vm.loading && _vm.showEmptyState ? _c('div', {
    staticClass: "transactions-empty"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/transaction.svg"),
      "alt": ""
    }
  }), _c('h3', [_vm._v("Nothing to see here")]), _c('p', [_vm._v("You’re yet to perform a transaction.")])]) : _c('ul', {
    staticClass: "transactions"
  }, [_vm.loading ? _vm._l(5, function (n) {
    return _c('transaction-skeleton', {
      key: n
    });
  }) : _vm._l(_vm.transactions, function (transaction, index) {
    return _c('transaction-card', _vm._b({
      key: index,
      attrs: {
        "status": transaction.status,
        "action": _vm._f("toUpperCase")(transaction.action),
        "image": transaction.sender_pic,
        "date": _vm._f("toFormattedDate")(transaction.updated_at),
        "amount": _vm.assignPolarity(transaction)
      }
    }, 'transaction-card', transaction, false));
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }