var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "cards-list"
  }, [_c('transition', {
    attrs: {
      "name": _vm.transitionName
    }
  }, [_c('li', {
    key: _vm.active,
    staticClass: "card"
  }, [_c('img', {
    staticClass: "card-design",
    attrs: {
      "lazy-load": "",
      "src": require(`@/assets/images/cards/${_vm.card.card_style}.png`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "card-details"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.card.custom_name))]), _c('div', {
    staticClass: "balance"
  }, [_vm.showBalance ? _c('p', {
    staticClass: "amount"
  }, [_c('span', [_vm._v(_vm._s(_vm.currencies[_vm.card.currency].symbol))]), _vm._v(_vm._s((_vm.card.balance / 100).toLocaleString()) + " ")]) : _c('p', {
    staticClass: "amount"
  }, [_c('span', [_vm._v("*")]), _vm._v("****")]), _c('button', {
    staticClass: "icon",
    on: {
      "click": function ($event) {
        _vm.showBalance = !_vm.showBalance;
      }
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.showBalance ? _c('img', {
    key: "1",
    attrs: {
      "src": require("@/assets/icons/eye-closed.svg"),
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/icons/eye-open.svg"),
      "alt": ""
    }
  })])], 1)])])])]), _vm.controls ? _c('div', {
    staticClass: "controls"
  }, [_vm.value !== 0 ? _c('button', {
    staticClass: "prev",
    on: {
      "click": function ($event) {
        return _vm.move('prev');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/arrow-left.svg"),
      "alt": ""
    }
  })]) : _vm._e(), _vm.value !== _vm.cards.length - 1 ? _c('button', {
    staticClass: "next",
    on: {
      "click": function ($event) {
        return _vm.move('next');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/arrow-right.svg"),
      "alt": ""
    }
  })]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "shadow"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }