import { computed, inject, onMounted } from "vue";
import { useTokenBalance } from "@/composables";
import useFreighterAccount from "@/composables/useFreighterAccount";

const usePortfolioBalance = () => {
  const isWeb3 = inject("isWeb3");
  const { fetchTokenBalance, balances } = useTokenBalance();
  const { balance: stellarBalance, isConnected: isFreighterConnected } =
    useFreighterAccount();

  console.log(stellarBalance, "balance");

  onMounted(async () => {
    if (isWeb3) {
      await fetchTokenBalance();
    }
  });

  const _balance = computed(() => {
    if (isFreighterConnected) {
      return stellarBalance.value;
    }

    return balances.ETH;
  });

  return {
    cryptoBalance: _balance,
    isFreighterConnected,
    isWeb3,
  };
};

export default usePortfolioBalance;
