import groupBy from "@/utils/group-by";

const bankArray = [
  {
    name: "Access Bank",
    id: "044",
  },
  {
    name: "Citibank",
    id: "023",
  },
  {
    name: "Diamond Bank",
    id: "063",
  },
  {
    name: "Ecobank Nigeria",
    id: "050",
  },
  {
    name: "Fidelity Bank Nigeria",
    id: "070",
  },
  {
    name: "First Bank of Nigeria",
    code: "FBN",
    id: "011",
  },
  {
    name: "First City Monument Bank",
    code: "FCMB",
    id: "214",
  },
  {
    name: "Guaranty Trust Bank",
    code: "GTB",
    id: "058",
    slug: "gtb",
  },
  {
    name: "Heritage Bank Plc",
    id: "030",
  },
  {
    name: "Jaiz Bank",
    id: "301",
  },
  {
    name: "Keystone Bank Limited",
    id: "082",
  },
  {
    name: "Kuda Bank",
    id: "090267",
    logoIRL:
      "https://raw.githubusercontent.com/theGrandmaster05/nigerialogos/b7a1d2b9543a86769c6a87ba8a24fa7c94059870/logos//kuda_bank/kuda_bank.png",
  },
  {
    name: "Providus Bank Plc",
    id: "101",
  },
  {
    name: "Polaris Bank",
    id: "076",
  },
  {
    name: "Stanbic IBTC Bank Nigeria Limited",
    id: "221",
  },
  {
    name: "Standard Chartered Bank",
    id: "068",
  },
  {
    name: "Sterling Bank",
    id: "232",
  },
  {
    name: "Suntrust Bank Nigeria Limited",
    code: "SBNL",
    id: "100",
  },
  {
    name: "Union Bank of Nigeria",
    id: "032",
  },
  {
    name: "United Bank for Africa",
    code: "UBA",
    id: "033",
  },
  {
    name: "Unity Bank Plc",
    id: "215",
  },
  {
    name: "Wema Bank",
    id: "035",
  },
  {
    name: "Zenith Bank",
    id: "057",
    slug: "zenith",
  },
];

export default groupBy(bankArray, ({ name }) => name);
