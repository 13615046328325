var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('container', {
    class: {
      desktop: !_vm.isMobile,
      mobile: _vm.isMobile
    },
    attrs: {
      "id": "app"
    }
  }, [!_vm.isMobile ? _c('header', [_c('img', {
    attrs: {
      "id": "logo",
      "src": require("@/assets/icons/flux.svg"),
      "alt": "flux logo"
    }
  })]) : _vm._e(), _c('div', {
    attrs: {
      "id": "flux-layout"
    }
  }, [_c('nav', {
    attrs: {
      "id": "side-panel"
    }
  }, [_c('ul', {
    staticClass: "nav-list"
  }, [_c('router-link', {
    key: "wallet",
    attrs: {
      "to": "/wallet",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var navigate = _ref.navigate,
          isExactActive = _ref.isExactActive;
        return [_c('li', {
          staticClass: "nav-item",
          class: {
            active: isExactActive || _vm.isWallet
          },
          attrs: {
            "data-link": "wallet",
            "role": "link"
          },
          on: {
            "click": navigate,
            "keypress": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              return navigate.apply(null, arguments);
            }
          }
        }, [_c('span', {
          staticClass: "nav-icon"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/wallet.svg"),
            "alt": ""
          }
        })]), _c('p', {
          staticClass: "nav-link"
        }, [_vm._v("Wallet")])])];
      }
    }])
  }), _c('router-link', {
    key: "crypto",
    attrs: {
      "to": "/crypto",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var navigate = _ref2.navigate,
          isExactActive = _ref2.isExactActive;
        return [_c('li', {
          staticClass: "nav-item",
          class: {
            active: isExactActive || _vm.isCrypto
          },
          attrs: {
            "data-link": "crypto"
          },
          on: {
            "click": navigate,
            "keypress": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              return navigate.apply(null, arguments);
            }
          }
        }, [_c('span', {
          staticClass: "nav-icon"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/crypto.svg"),
            "alt": ""
          }
        })]), _c('p', {
          staticClass: "nav-link"
        }, [_vm._v("Crypto")])])];
      }
    }])
  }), _c('router-link', {
    key: "web3-wallet",
    attrs: {
      "to": "/web3-wallet",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var navigate = _ref3.navigate,
          isExactActive = _ref3.isExactActive;
        return [_c('li', {
          staticClass: "nav-item",
          class: {
            active: isExactActive || _vm.isWeb3
          },
          attrs: {
            "data-link": "web3-wallet"
          },
          on: {
            "click": navigate,
            "keypress": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              return navigate.apply(null, arguments);
            }
          }
        }, [_c('span', {
          staticClass: "nav-icon"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/crypto.svg"),
            "alt": ""
          }
        })]), _c('p', {
          staticClass: "nav-link"
        }, [_vm._v("Web3")])])];
      }
    }])
  }), !_vm.isMobile ? _c('li', {
    staticClass: "nav-item",
    attrs: {
      "data-link": "signout",
      "role": "option"
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('span', {
    staticClass: "nav-icon"
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "-5 -3 24 24",
      "width": "24",
      "fill": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "d": "M3.414 7.828h5.642a1 1 0 1 1 0 2H3.414l1.122 1.122a1 1 0 1 1-1.415 1.414L.293 9.536a.997.997 0 0 1 0-1.415L3.12 5.293a1 1 0 0 1 1.415 1.414L3.414 7.828zM13 0a1 1 0 0 1 1 1v16a1 1 0 0 1-2 0V1a1 1 0 0 1 1-1z"
    }
  })])]), _c('p', {
    staticClass: "nav-link"
  }, [_vm._v("Sign Out")])]) : _vm._e()], 1)]), _c('div', {
    attrs: {
      "id": "main-panel"
    }
  }, [_c('router-view')], 1)]), _c('modal', {
    attrs: {
      "show": _vm.showModal,
      "dismissible": true,
      "backdrop": false,
      "animation": "scale",
      "title": "Email Verification"
    },
    on: {
      "close": function ($event) {
        _vm.showModal = false;
      }
    }
  }, [_c('div', {
    staticClass: "email-verification-popup"
  }, [_c('div', {
    staticClass: "body"
  }, [_vm._v(" Your email has not been verified. Please verify your email to access all the features of Flux. ")]), _c('div', {
    staticClass: "footer"
  }, [_c('elevated-button', {
    attrs: {
      "icon": false,
      "text": "I have verified my email."
    },
    on: {
      "click": function ($event) {
        _vm.showModal = false;
      }
    }
  })], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }