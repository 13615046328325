<template>
  <div :key="key" id="wallet">
    <template v-if="isMobile">
      <profile-bar />
      <wallet-balance />
      <wallet-features />
      <wallet-history height="100%" />
      <!-- <div class="spacer" :style="{ height: '75px' }"></div> -->
    </template>
    <div v-else class="wallet">
      <template>
        <div class="left">
          <profile-bar />
          <wallet-balance />
          <wallet-features />
          <wallet-beneficiaries />
        </div>
        <div class="right">
          <wallet-history height="40%" />
          <hr />
          <wallet-cards height="60%" />
        </div>
      </template>
    </div>

    <transition name="slide-out">
      <aside v-if="childRoute" id="drawer" :class="{ mobile: isMobile }">
        <div class="drawer-body">
          <transition name="fade" mode="out-in">
            <router-view name="sidebar" />
          </transition>
        </div>
      </aside>
    </transition>
  </div>
</template>

<script>
import {
  WalletBalance,
  WalletFeatures,
  WalletBeneficiaries,
  WalletHistory,
  WalletCards,
} from "@/fragments";
import { ProfileBar } from "@/partials";
import { mapGetters } from "vuex";

export default {
  name: "Wallet",
  components: {
    ProfileBar,
    WalletBalance,
    WalletFeatures,
    WalletBeneficiaries,
    WalletHistory,
    WalletCards,
  },
  watch: {
    "$route.path"(newValue) {
      if (newValue === "/wallet") this.key = !this.key;
    },
  },
  data() {
    return {
      key: true,
    };
  },
  computed: {
    childRoute() {
      return this.$route.path.startsWith("/wallet/");
    },
    ...mapGetters(["isMobile"]),
  },
  methods: {
    handleKeys(e) {
      if (e.code === "Escape") this.$router.push("/wallet");
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeys);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeys);
  },
};
</script>

<style lang="scss" scoped>
#wallet {
  height: inherit;
}

.wallet {
  border-radius: 12px;
  border: 1px solid #222;
  border-collapse: collapse;
  height: inherit;
  /* overflow: hidden; */
  display: grid;
  grid-template-columns: 1fr 43%;
}

.left {
  border-right: 0.5px solid #222;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.right {
  border-left: 0.5px solid #222;
  height: inherit;
}
</style>
