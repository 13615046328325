var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('container', {
    class: {
      desktop: !_vm.isMobile,
      mobile: _vm.isMobile
    },
    attrs: {
      "id": "app"
    }
  }, [_c('header', [_c('router-link', {
    attrs: {
      "to": "/auth/login",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var navigate = _ref.navigate;
        return [_c('button', {
          attrs: {
            "role": "button",
            "id": "logo"
          },
          on: {
            "click": navigate,
            "keypress": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              return navigate.apply(null, arguments);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/flux.svg"),
            "alt": "flux logo"
          }
        }), _c('span', {
          staticClass: "badge"
        }, [_vm._v("BANK")])])];
      }
    }])
  })], 1), _c('div', {
    attrs: {
      "id": "flux-layout"
    }
  }, [!_vm.isMobile ? _c('nav', {
    attrs: {
      "id": "side-panel"
    }
  }, [_c('ul', {
    staticClass: "nav-list"
  }, [_c('router-link', {
    attrs: {
      "to": "/contact",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var navigate = _ref2.navigate;
        return [_c('li', {
          staticClass: "nav-item",
          attrs: {
            "data-link": "contact",
            "role": "link"
          },
          on: {
            "click": navigate,
            "keypress": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              return navigate.apply(null, arguments);
            }
          }
        }, [_c('span', {
          staticClass: "nav-icon"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/contact.svg"),
            "alt": ""
          }
        })]), _c('p', {
          staticClass: "nav-link"
        }, [_vm._v(" Need help? "), _c('br'), _c('span', {
          staticClass: "subtitle"
        }, [_vm._v("Contact Support")])])])];
      }
    }], null, false, 2565987674)
  })], 1)]) : _vm._e(), _c('div', {
    attrs: {
      "id": "main-panel"
    }
  }, [_c('router-view')], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }