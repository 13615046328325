<template>
  <card class="balance">
    <div class="heading">
      <span>Balance</span>
      <dropdown v-model="currency" use-title :options="options" />
    </div>
    <div class="balance-available">
      <h2>
        <template v-if="!hidden">
          {{ balance.available_balance | toCurrency(currency) }}
        </template>
        <template v-else> ******<span>.**</span> </template>
      </h2>
      <button class="icon" @click="hidden = !hidden">
        <transition name="fade" mode="out-in">
          <img
            v-if="hidden"
            :key="hidden.toString()"
            src="@/assets/icons/eye-open.svg"
            alt=""
          />
          <img v-else src="@/assets/icons/eye-closed.svg" alt="" />
        </transition>
      </button>
    </div>
    <div class="balance-locked">
      <img
        class="icon"
        src="@/assets/icons/feather-lock.svg"
        alt="A padlock icon"
      />

      <p>
        <template v-if="!hidden">
          {{ balance.ledger_balance || 0 | toCurrency(currency) }}
        </template>
        <template v-else> ******<span>.**</span> </template>
      </p>
    </div>
  </card>
</template>

<script>
import { Card, Dropdown } from "@/components";
import { mapGetters } from "vuex";

export default {
  name: "WalletBalance",
  components: {
    Card,
    Dropdown,
  },
  data() {
    return {
      currency: "NGN",
      options: [
        {
          option: "NGN",
          title: "Naira Wallet",
        },
        {
          option: "EUR",
          title: "Euro Wallet",
        },
        {
          option: "USD",
          title: "Dollar Wallet",
        },
      ],
    };
  },
  computed: {
    hidden: {
      get() {
        return this.$store.getters["user/hideBalance"];
      },
      set(value) {
        this.$store.commit("user/setHideBalance", value);
      },
    },
    ...mapGetters({ user: "user/user", savings: "user/savings" }),
    balance() {
      if (this.currency === "NGN") {
        return {
          available_balance: this.user.split_balance.available_balance,
          ledger_balance: this.user.split_balance.ledger_balance,
        };
      } else {
        const balance = this.savings?.find(
          (saving) => saving?.currency === this.currency
        );

        return {
          available_balance: balance?.balance ?? 0,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.balance {
  margin: 10px 28px 0;

  .heading {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-available {
    display: flex;
    align-items: center;

    h2 {
      letter-spacing: -1px;
      font-size: 2.2rem;
    }

    span {
      color: #222;
      font-size: 60%;
    }

    .icon {
      width: 28px;
      height: 28px;
      background: #222;
      border-radius: 50%;
      margin-left: 9px;
      display: block;
      display: grid;
      place-items: center;
      cursor: pointer;
      border: 2px solid black;

      * {
        width: 50%;
      }
    }
  }

  &-locked {
    display: flex;
    align-items: center;
    margin-top: 9px;

    p {
      letter-spacing: -1px;
      font-size: 1.4rem;
    }

    span {
      color: #222;
      font-size: 60%;
    }

    .icon {
      height: 11.9px;
      margin-right: 9px;
    }
  }
}
</style>
