<template>
  <div class="transaction-history">
    <div class="header-wrapper">
      <section-header title="Spot Crypto Portfolio" />

      <button v-if="isMobile" @click="showSearchBar = !showSearchBar">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.121"
          height="17.121"
          viewBox="0 0 17.121 17.121"
        >
          <g
            id="Icon_feather-search"
            data-name="Icon feather-search"
            transform="translate(1 1)"
          >
            <path
              id="Path_2025"
              data-name="Path 2025"
              d="M17.573,11.036A6.536,6.536,0,1,1,11.036,4.5a6.536,6.536,0,0,1,6.536,6.536Z"
              transform="translate(-4.5 -4.5)"
              fill="#fff"
              stroke="#855AAF"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="Path_2026"
              data-name="Path 2026"
              d="M28.529,28.529l-3.554-3.554"
              transform="translate(-13.822 -13.822)"
              stroke="#855AAF"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
        </svg>

        Search
      </button>
    </div>
    <transition name="fade" mode="out-in">
      <div v-if="!isMobile || showSearchBar" class="search-bar">
        <i>
          <img src="@/assets/icons/feather-search.svg" alt="magnifying glass" />
        </i>
        <input
          v-model="query"
          type="search"
          placeholder="Search cryptocurrency"
        />
      </div>
    </transition>
    <div v-if="false" class="transactions-empty">
      <img src="@/assets/icons/transaction.svg" alt="" />
      <h3>Nothing to see here</h3>
      <p>You’re yet to perform a transaction.</p>
    </div>
    <ul v-else class="transactions">
      <template v-if="fetching">
        <li v-for="n in 8" :key="n">
          <transaction-skeleton />
        </li>
      </template>
      <template v-else>
        <portfolio-card
          v-for="(token, index) in filteredTokens"
          :key="index"
          v-bind="token"
          @click="viewDetails(token.ref)"
        />
        <button class="show-more" @click="showMore = !showMore">
          show {{ showMore ? "less" : "more"}}
        </button>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { SectionHeader } from "@/components";
import PortfolioCard from "./portfolio-card.vue";
import { TransactionSkeleton } from "@/fragments";

export default {
  name: "CryptoPortfolio",
  components: {
    SectionHeader,
    PortfolioCard,
    TransactionSkeleton,
  },
  data() {
    return {
      query: null,
      fetching: true,
      showSearchBar: false,
      mainCoins: ["XLM", "USDT", "BTC", "ETH", "BUSD", "BNB"],
      showMore: false,
    };
  },
  computed: {
    ...mapGetters({ isMobile: "isMobile" }),
    tokens() {
      // return this.transaction === "buy"
      //   ? this.$store.getters["crypto/allowedTokens"]
      //   : this.$store.getters["crypto/allowedTokens"].filter(
      //       (token) => token.balance != 0
      //     );
      const tokens = this.$store.getters["crypto/tokens"];

      tokens.sort((a, b) => b.balance - a.balance).sort(this.sortTokens);

      return tokens;
    },
    mainTokens() {
      return this.tokens.filter((token) =>
        this.mainCoins.includes(token.asset)
      );
    },
    irrelevantTokens() {
      return this.tokens.filter(
        (token) => !this.mainCoins.includes(token.asset)
      );
    },
    filteredTokens() {
      if (this.query) {
        const query = this.query.toLowerCase();

        return [...this.mainTokens, ...this.irrelevantTokens].filter(
          (token) =>
            token.asset.toLowerCase().includes(query) ||
            token.network.toLowerCase().includes(query)
        );
      }
      return !this.showMore
        ? this.mainTokens
        : [...this.mainTokens, ...this.irrelevantTokens];
    },
  },
  mounted() {
    this.fetchTokens();
  },
  methods: {
    sortTokens(a, b) {
      if (
        this.mainCoins.includes(a.asset) &&
        !this.mainCoins.includes(b.asset)
      ) {
        return -1;
      }

      if (
        !this.mainCoins.includes(a.asset) &&
        this.mainCoins.includes(b.asset)
      ) {
        return 1;
      }
      return 0;
    },
    async fetchTokens() {
      this.fetching = true;
      try {
        await this.$store.dispatch("crypto/getTokens");
      } finally {
        this.fetching = false;
      }
    },
    viewDetails(ref) {
      this.$router.push({
        name: "TokenOverview",
        params: { token_ref: ref },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.transaction-history {
  padding: 20px 28px 0;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .section-header {
      margin-bottom: 0;
    }

    button {
      background: none;
      color: map-get($map: $colours, $key: purple);
      display: flex;
      align-items: center;
      font-size: 1.4rem;

      svg {
        height: 12px;
        margin-right: 2px;
        fill: map-get($map: $colours, $key: purple);
      }
    }
  }

  .search-bar {
    border-radius: 8px;
    background: #e1e1e14d;
    padding: 13px 20px;
    display: grid;
    align-items: center;
    grid-template-columns: 14px 1fr;
    grid-column-gap: 12px;

    input {
      outline: none;
      border: none;
      background: none;
      font-size: 1.2rem;

      &::placeholder {
        font-weight: 300;
      }
    }
  }

  .transactions {
    display: grid;
    grid-gap: 20px;
    grid-template-rows: repeat(auto-fill, minmax(30px, 1fr));
    padding-top: 20px;
    flex-basis: 100%;
    padding-bottom: 100px;
    overflow: auto;
    @include hide-scrollbar();

    li {
      cursor: pointer;
    }
  }

  .show-more {
    color: map-get($colours, primary);
    background-color: transparent;
    cursor: pointer;
  }

  .transactions-empty {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    img {
      margin-bottom: 24px;
    }

    h3 {
      font-size: 1.6rem;
    }

    p {
      margin-top: 4px;
      font-size: 1.4rem;
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2px;
    height: 100px;
    border-radius: 20px;
    background-image: linear-gradient(to bottom, transparent, #fff);
  }
}
</style>
