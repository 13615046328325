import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";
import authRoutes from "@/router/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/wallet",
  },
  // Wallet Pages
  {
    path: "/wallet",
    name: "Home",
    component: Home,
    props: true,
    children: [
      // Add Cash
      {
        path: "add-cash",
        name: "AddCash",
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "add-cash" */ "@/fragments/Drawers/AddCash"
            ),
        },
      },
      // Send Cash
      {
        path: "send-cash",
        name: "SendCash",
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "send-cash" */ "@/fragments/Drawers/SendCash"
            ),
        },
      },
      {
        path: "send-cash/:type",
        name: "SelectTransferType",
        props: { sidebar: true },
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "send-cash" */ "@/fragments/Drawers/SelectTransferType"
            ),
        },
      },
      {
        path: "send-cash/:type/details",
        name: "TransferDetails",
        props: { sidebar: true },
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "send-cash" */ "@/fragments/Drawers/SelectTransferType/transaction-details"
            ),
        },
      },
      // Safe
      {
        path: "safe",
        name: "Safe",
        components: {
          sidebar: () =>
            import(/* webpackChunkName: "safe" */ "@/fragments/Drawers/Safe"),
        },
      },
      {
        path: "safe/create",
        name: "CreateSafe",
        components: {
          // default: Home,
          sidebar: () =>
            import(
              /* webpackChunkName: "safe" */ "@/fragments/Drawers/Safe/create-safe"
            ),
        },
      },
      {
        path: "safe/confirm",
        name: "ConfirmSafe",
        components: {
          // default: Home,
          sidebar: () =>
            import(
              /* webpackChunkName: "safe" */ "@/fragments/Drawers/Safe/confirm-safe"
            ),
        },
      },
      {
        path: "safe/plans/:plan_id",
        name: "ViewSafePlan",
        props: { sidebar: true },
        components: {
          // default: Home,
          sidebar: () =>
            import(
              /* webpackChunkName: "safe" */ "@/fragments/Drawers/Safe/plan-details"
            ),
        },
      },
      // Airtime
      {
        path: "buy-airtime",
        name: "BuyAirtime",
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "airtime" */ "@/fragments/Drawers/BuyAirtime"
            ),
        },
      },
      // Bill Payment
      {
        path: "pay-bills",
        name: "PayBills",
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "pay-bills" */ "@/fragments/Drawers/PayBills"
            ),
        },
      },
      {
        path: "pay-bills/payment-method",
        name: "SelectBillMethod",
        components: {
          sidebar: () =>
            import(/* webpackChunkName: "billPaymentMethod" */ "@/fragments/Drawers/PayBills/payment-method")
        }
      },
      {
        path: "pay-bills/:slug",
        name: "BillsPayment",
        props: { sidebar: true },
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "pay-bills" */ "@/fragments/Drawers/PayBills/bill-payment"
            ),
        },
      },
      // Beneficiaries
      {
        path: "beneficiaries",
        name: "Beneficiaries",
        props: true,
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "beneficiaries" */ "@/fragments/Drawers/Beneficiaries"
            ),
        },
      },
      {
        path: "beneficiaries/add",
        name: "AddBeneficiary",
        props: true,
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "beneficiaries" */ "@/fragments/Drawers/Beneficiaries/add-beneficiary"
            ),
        },
      },
      // Card
      {
        path: "card/create",
        name: "CreateCard",
        props: true,
        redirect: store.getters.isMobile ? { name: "MobileCreateCard" } : null,
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "card" */ "@/fragments/Drawers/CardDetails/create-card"
            ),
        },
      },
      {
        path: "card/:card_id",
        name: "CardDetails",
        props: { sidebar: true },
        redirect: store.getters.isMobile ? { name: "MobileCardDetails" } : null,
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "card" */ "@/fragments/Drawers/CardDetails"
            ),
        },
      },
      {
        path: "card/:card_id/options",
        name: "CardActions",
        props: { sidebar: true },
        redirect: store.getters.isMobile ? { name: "MobileCardActions" } : null,
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "card" */ "@/fragments/Drawers/CardDetails/actions"
            ),
        },
      },
      {
        path: "card/:card_id/topup",
        name: "CardTopup",
        props: { sidebar: true },
        redirect: store.getters.isMobile ? { name: "MobileCardTopup" } : null,
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "card" */ "@/fragments/Drawers/CardDetails/topup"
            ),
        },
      },
      // Transactions
      {
        path: "account-statement",
        name: "AccountStatement",
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "account-statement" */ "@/fragments/Drawers/AccountStatement"
            ),
        },
      },
    ],
  },
  // Crypto Pages
  {
    path: "/crypto",
    name: "Crypto",
    component: () =>
      import(/* webpackChunkName: "crypto" */ "../views/Crypto.vue"),
    children: [
      // Deposit Token
      {
        path: "deposit-token",
        name: "DepositToken",
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "deposit-token" */ "@/fragments/Drawers/DepositToken"
            ),
        },
      },
      {
        path: "deposit-token/:token_ref",
        name: "DepositTokenAddress",
        props: { sidebar: true },
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "deposit-token" */ "@/fragments/Drawers/DepositToken/deposit"
            ),
        },
      },
      // Token Overview
      {
        path: "token/:token_ref",
        name: "TokenOverview",
        props: { sidebar: true },
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "deposit-token" */ "@/fragments/Drawers/CoinDetails"
            ),
        },
      },
      // Token Transaction
      {
        path: ":transaction-token",
        name: "TransactToken",
        props: { sidebar: true },
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "token" */ "@/fragments/Drawers/TransactToken"
            ),
        },
      },
      // Buy Token
      {
        path: "buy-token/:token_ref",
        name: "BuyTokenLocation",
        props: { sidebar: true },
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "token" */ "@/fragments/Drawers/TransactToken/select-purchase-location"
            ),
        },
      },
      // Sell Token
      {
        path: "sell-token/:token_ref",
        name: "SellTokenLocation",
        props: { sidebar: true },
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "token" */ "@/fragments/Drawers/TransactToken/select-sell-location"
            ),
        },
      },
      // Send Token
      {
        path: "send-token/:token_ref",
        name: "SendTokenLocation",
        props: { sidebar: true },
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "token" */ "@/fragments/Drawers/TransactToken/select-send-location"
            ),
        },
      },
    ],
  },
  {
    path: "/web3-wallet",
    name: "Web3Wallet",
    component: () =>
      import(/* webpackChunkName: "web3wallet" */ "@/views/web3wallet/index"),
    children: [
      {
        path: "trade",
        name: "TradeP2P",
        props: true,
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "tradep2p" */ "@/views/web3wallet/features/TradeP2P"
            ),
        },
      },
      {
        path: "listings",
        name: "Listings",
        props: true,
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "listing" */ "@/views/web3wallet/features/P2PListings"
            ),
        },
      },
      {
        path: "list-trade",
        name: "ListTrade",
        props: true,
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "listTrade" */ "@/views/web3wallet/features/ListTrade"
            ),
        },
      },
      {
        path: "select-token",
        name: "SelectToken",
        props: true,
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "selecttoken" */ "@/views/web3wallet/features/CreateTrade/features/SelectCoin"
            ),
        },
      },
      {
        path: "create-trade",
        name: "CreateTrade",
        props: true,
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "createTrade" */ "@/views/web3wallet/features/CreateTrade"
            ),
        },
      },
      {
        path: "t/:ref",
        name: "ViewTrade",
        props: true,
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "viewTrade" */ "@/views/web3wallet/features/Trade"
            ),
        },
      },
      {
        path: "select",
        name: "Select",
        props: true,
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "selectWallet" */ "@/fragments/Drawers/SelectWallet"
            ),
        },
      },
    ],
  },
  // Cards Pages
  {
    path: "/cards",
    name: "MobileCards",
    redirect: store.getters.isMobile ? null : "/wallet",
    component: () =>
      import(/* webpackChunkName: "crypto" */ "@/views/Cards.vue"),
    children: [
      {
        path: "create",
        name: "MobileCreateCard",
        props: true,
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "card" */ "@/fragments/Drawers/CardDetails/create-card"
            ),
        },
      },
      {
        path: ":card_id",
        name: "MobileCardDetails",
        props: true,
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "card" */ "@/fragments/Drawers/CardDetails"
            ),
        },
      },
      {
        path: ":card_id/options",
        name: "MobileCardActions",
        props: true,
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "card" */ "@/fragments/Drawers/CardDetails/actions"
            ),
        },
      },
      {
        path: ":card_id/topup",
        name: "MobileCardTopup",
        props: true,
        components: {
          sidebar: () =>
            import(
              /* webpackChunkName: "card" */ "@/fragments/Drawers/CardDetails/topup"
            ),
        },
      },
    ],
  },
  // Auth Pages
  ...authRoutes,
  {
    path: "*",
    redirect: { name: "NotFound" },
  },
  {
    path: "/404",
    name: "NotFound",
    component: Home,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
