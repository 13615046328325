import freighter from "@stellar/freighter-api";
import { onMounted, ref, watch } from "vue";
import toast from "vue-m-message";
import { Asset, Horizon, Networks, Operation, TransactionBuilder } from "@stellar/stellar-sdk";

const server = new Horizon.Server("https://horizon.stellar.org");

const stellarNetworks = {
  PUBLIC: "stellar:pubnet"
};

const useFreighterAccount = () => {
  const isConnected = ref(false);

  const account = ref({});
  const balance = ref("0");

  const signTransaction = async ({ recipientPublicKey, amount }) => {
    const _account = await server.loadAccount(await freighter.getPublicKey());

    console.log(Asset.native());

    const transaction = new TransactionBuilder(_account, {
      fee: await server.fetchBaseFee(),
      networkPassphrase: Networks.PUBLIC
    })
      .addOperation(Operation.payment({
        destination: recipientPublicKey,
        amount,
        asset: Asset.native()
      }))
      .setTimeout(180)
      .build();
    console.log(transaction, 'matseball');

    const transactionXDR = transaction.toXDR();
    console.log(transactionXDR, "xdr");
    try {
      return await freighter.signTransaction(transactionXDR, {
        network: "PUBLIC"
      });
    } catch (e) {
      console.table(e);
      handleError(e);
    }
  };

  const getConnectionStatus = async () => {
    try {
      const checkFreighterConnection = freighter.isConnected;
      const checkFreighterAllowance = freighter.isAllowed;

      const [isFreighterConnected, isFreighterAllowed] = await Promise.all([
        checkFreighterConnection(),
        checkFreighterAllowance()
      ]);

      isConnected.value = isFreighterConnected && isFreighterAllowed;
    } catch (e) {
      handleError();
    }
  };

  const getAccount = async () => {
    let _account = {};
    try {
      const userInfo = await freighter.getUserInfo();
      const networkInfo = await freighter.getNetwork();
      _account.address = userInfo.publicKey;
      _account.network = stellarNetworks[networkInfo];
      _account = {
        ..._account,
        ...userInfo
      };

      account.value = _account;
    } catch (e) {
      handleError(e);
    }
  };

  const getBalance = async () => {
    try {
      if (account.value?.address) {
        const accountBalance = await server.loadAccount(account.value.address);
        const _balance = accountBalance.balances.find(
          (balance) => balance.asset_type === "native"
        );

        if (_balance) {
          balance.value = _balance.balance;
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  watch(isConnected, async (newValue) => {
    await getAccount();
    if (newValue) {
      await getBalance();
    } else {
      account.value = {};
    }
  });

  onMounted(async () => {
    await getConnectionStatus();
    await getAccount();
  });

  return {
    isConnected,
    refreshConnectionStatus: getConnectionStatus,
    account,
    balance,
    signTransaction,
  };
};

const handleError = (e) => {
  if (typeof e === "string") {
    toast.error(e);
  }
};

export default useFreighterAccount;
