<template>
  <div class="features">
    <feature-button
      v-for="(f, index) in features"
      :key="index"
      :background="f.background"
      :title="f.title"
      @click="f.onTap ? f.onTap() : null"
    >
      <template #icon>
        <img :src="f.src" :alt="f.alt" />
      </template>
    </feature-button>
  </div>
</template>

<script>
import { FeatureButton } from "@/components";
export default {
  name: "WalletFeatures",
  components: {
    FeatureButton,
  },
  data() {
    return {
      features: [
        {
          background: "#FFC400",
          title: "Add Cash",
          src: require("@/assets/icons/plus.svg"),
          alt: "plus icon",
          onTap: () => {
            this.$router.push({ name: "AddCash" });
          },
        },
        {
          background: "#4187FF",
          title: "Send Cash",
          src: require("@/assets/icons/send.svg"),
          alt: "airplane icon",
          onTap: () => {
            this.$router.push({ name: "SendCash" });
          },
        },
        {
          background: "#47C479",
          title: "Safe",
          src: require("@/assets/icons/safe.svg"),
          alt: "piggy bank icon",
          onTap: () => {
            this.$router.push({ name: "Safe" });
          },
        },
        // {
        //   background: "#855AAF",
        //   title: "Buy Airtime",
        //   src: require("@/assets/icons/phone.svg"),
        //   alt: "phone icon",
        //   onTap: () => {
        //     this.$router.push({ name: "BuyAirtime" });
        //     // this.$drawer.open({
        //     //   componentName: "buy-airtime",
        //     //   title: "Buy Airtime",
        //     // });
        //   },
        // },
        {
          background: "#855AAF",
          title: "Pay Bills",
          src: require("@/assets/icons/phone.svg"),
          alt: "phone icon",
          onTap: () => {
            this.$router.push({ name: "PayBills" });
            // this.$drawer.open({
            //   componentName: "buy-airtime",
            //   title: "Buy Airtime",
            // });
          },
        },
        {
          background: "#FC6190",
          title: "Statement",
          src: require("@/assets/icons/feather-document.svg"),
          alt: "document icon",
          onTap: () => {
            this.$router.push({ name: "AccountStatement" });
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.features {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: min-content;
  grid-gap: 30px;
  padding: 35px 28px;

  * {
    grid-column: span 2;

    &:nth-child(1),
    &:nth-child(2) {
      grid-column: span 3;
    }
  }
}
</style>
