<template>
  <div class="features">
    <feature-button
      v-for="(f, index) in features"
      :key="index"
      :background="f.background"
      :title="f.title"
      @click="f.onTap ? f.onTap() : null"
    >
      <template #icon>
        <img :src="f.src" :alt="f.alt" />
      </template>
    </feature-button>
  </div>
</template>

<script>
import { FeatureButton } from "@/components";

export default {
  name: "WalletFeatures",
  components: {
    FeatureButton,
  },
  data() {
    return {
      features: [
        {
          background: "#855AAF",
          title: "Buy Airtime",
          src: require("@/assets/icons/phone.svg"),
          alt: "phone icon",
          onTap: () => {
            this.$router.push({ name: "BuyAirtime" });
          },
        },
        // {
        //   background: "#FC6190",
        //   title: "Account Statement",
        //   src: require("flux-pay/assets/icons/tv.svg"),
        //   alt: "television icon",
        //   onTap: () => {
        //     this.$router.push({ name: "PayBills" });
        //   },
        // },
        {
          background: "#FC6190",
          title: "Pay Bills",
          src: require("@/assets/icons/phone.svg"),
          alt: "phone icon",
          onTap: () => {
            this.$router.push({ name: "PayBills" });
            // this.$drawer.open({
            //   componentName: "buy-airtime",
            //   title: "Buy Airtime",
            // });
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.features {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  grid-template-rows: min-content;
  grid-gap: 30px;
  padding: 35px 28px;

  :deep(.feature-button) {
    grid-column: span 2;
  }
}
</style>
