export default {
  namespaced: true,
  state: () => ({
    bills: [
      {
        background: "#FFCC00",
        title: "Utilities",
        description: "Settle your bills for power, water and waste management.",
        src: require("@/assets/icons/lightning.svg"),
        alt: "lightning bolt icon",
        slug: "POWER",
      },
      {
        background: "#855AAF",
        title: "TV & Entertainment",
        description:
          "Pay for Cable TV and buy movie & show tickets without stress.",
        src: require("@/assets/icons/satellite.svg"),
        alt: "satellite icon",
        slug: "CABLES",
      },
      {
        background: "#4187FF",
        title: "Internet",
        description: "Pay for your internet subscriptions.",
        src: require("@/assets/icons/phone.svg"),
        alt: "phone icon",
        slug: "INTERNET",
      },
    ],
    activeBill: null,
  }),
  mutations: {
    SET_BILL(state, bill) {
      state.activeBill = bill;
    },
  },
  getters: {
    bills(state) {
      return state.bills;
    },
    activeBill(state) {
      return state.activeBill;
    },
  },
};
