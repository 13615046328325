var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.show ? _c('div', {
    staticClass: "F-modal-backdrop",
    class: {
      show: _vm.backdrop
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.dismissible ? _vm.close() : null;
      }
    }
  }) : _vm._e()]), _c('transition', {
    attrs: {
      "name": _vm.animation
    }
  }, [_vm.show ? _c('div', {
    ref: `F-modal-${_vm.id}`,
    staticClass: "F-modal",
    attrs: {
      "id": `F-modal-${_vm.id}`,
      "aria-labelledby": `modal-${_vm.id}-title`,
      "tabindex": "-1"
    }
  }, [!_vm.hideHeader ? _c('div', {
    staticClass: "header"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _vm.showClose ? _c('button', {
    staticClass: "icon",
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "alt": "",
      "src": require("@/assets/icons/plus.svg")
    }
  })]) : _vm._e()]) : _vm._e(), _vm._t("default", function () {
    return [_c('div', {
      staticClass: "body"
    }, [_c('p', {
      staticClass: "body-text"
    }, [_vm._v(" " + _vm._s(_vm.body) + " ")])])];
  })], 2) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }