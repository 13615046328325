export default {
  namespaced: true,
  state: () => ({
    provider: null,
  }),
  getters: {
    // getBalance(state) {
    //
    // }
  },
  mutations: {
    setProvider(state, provider) {
      state.provider = provider;
    },
  },
  actions: {},
};
