var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dropdown",
    attrs: {
      "aria-expanded": _vm.show
    }
  }, [_c('button', {
    staticClass: "dropdown-selected",
    class: {
      open: _vm.show
    },
    attrs: {
      "role": "button"
    },
    on: {
      "click": function ($event) {
        _vm.show = !_vm.show;
      }
    }
  }, [_vm.showCircle ? _c('span', {
    staticClass: "circle"
  }) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.selectedDisplay))]), _vm._m(0)]), _c('transition', {
    attrs: {
      "mode": "out-in",
      "name": "fade-in"
    }
  }, [_c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "dropdown-options",
    attrs: {
      "aria-expanded": _vm.show
    }
  }, _vm._l(_vm.options, function (option) {
    return _c('li', {
      key: option.option,
      staticClass: "dropdown-option",
      class: {
        active: _vm.$options.filters.formatOption(option) === _vm.value
      },
      attrs: {
        "role": "listbox",
        "tabindex": "0"
      },
      on: {
        "click": function ($event) {
          return _vm.setActive(option);
        },
        "keypress": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return _vm.setActive(option);
        }
      }
    }, [_c('span', {
      staticClass: "checkbox",
      attrs: {
        "role": "checkbox"
      }
    }), _c('p', [_vm._v(" " + _vm._s(_vm._f("formatOption")(option)) + " ")])]);
  }), 0)])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('i', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "alt": "",
      "src": require("@/assets/icons/feather-arrow-down.svg")
    }
  })]);

}]

export { render, staticRenderFns }