export default {
  erc20: [
    "function balanceOf(address) view returns (uint)",
    "function name() view returns (string)",
    "function symbol() view returns (string)",
    "event Transfer(address indexed from, address indexed to, uint amount)",
    "function decimals() view returns (uint8)",
    "function transfer(address recipient, uint256 amount) external returns (bool)",
  ],
};

export const erc20ContractAddresses = {
  USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
};
