var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    staticClass: "balance"
  }, [_c('div', {
    staticClass: "heading"
  }, [_c('span', [_vm._v("Balance")]), _c('dropdown', {
    attrs: {
      "use-title": "",
      "options": _vm.options
    },
    model: {
      value: _vm.currency,
      callback: function ($$v) {
        _vm.currency = $$v;
      },
      expression: "currency"
    }
  })], 1), _c('div', {
    staticClass: "balance-available"
  }, [_c('h2', [!_vm.hidden ? [_vm._v(" " + _vm._s(_vm._f("toCurrency")(_vm.balance.available_balance, _vm.currency)) + " ")] : [_vm._v(" ******"), _c('span', [_vm._v(".**")])]], 2), _c('button', {
    staticClass: "icon",
    on: {
      "click": function ($event) {
        _vm.hidden = !_vm.hidden;
      }
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.hidden ? _c('img', {
    key: _vm.hidden.toString(),
    attrs: {
      "src": require("@/assets/icons/eye-open.svg"),
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/icons/eye-closed.svg"),
      "alt": ""
    }
  })])], 1)]), _c('div', {
    staticClass: "balance-locked"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/icons/feather-lock.svg"),
      "alt": "A padlock icon"
    }
  }), _c('p', [!_vm.hidden ? [_vm._v(" " + _vm._s(_vm._f("toCurrency")(_vm.balance.ledger_balance || 0, _vm.currency)) + " ")] : [_vm._v(" ******"), _c('span', [_vm._v(".**")])]], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }