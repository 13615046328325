var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "slide-out"
    }
  }, [_vm.show ? _c('aside', {
    ref: "drawer",
    attrs: {
      "id": "drawer"
    }
  }, [_c('div', {
    staticClass: "drawer-body"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('button', {
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "alt": "",
      "src": require("@/assets/icons/arrow-left.svg")
    }
  })]), _c('h3', [_vm._v(_vm._s(_vm.title))])]), _c(_vm.component, {
    tag: "component"
  })], 1)]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }