const chainIdToNetwork = (chainId) => {
  switch (chainId) {
    case 1:
      return "Ethereum Mainnet";
    case 56:
      return "Binance Smart Chain";
    default:
      return "Unknown Network";
  }
};

export default chainIdToNetwork;
