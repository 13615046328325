var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "card-options"
    }
  }, [_c('ul', {
    staticClass: "options"
  }, _vm._l(_vm.options, function (option) {
    return _c('li', {
      key: option.title,
      staticClass: "option"
    }, [_c('card-button', _vm._b({
      attrs: {
        "reverse": ""
      },
      on: {
        "click": function ($event) {
          option.onTap ? option.onTap() : null;
        }
      },
      scopedSlots: _vm._u([{
        key: "icon",
        fn: function () {
          return [_c('img', {
            attrs: {
              "src": option.src,
              "alt": option.alt
            }
          })];
        },
        proxy: true
      }], null, true)
    }, 'card-button', option, false))], 1);
  }), 0), [_c('modal', {
    attrs: {
      "show": _vm.showModal.deactivate,
      "dismissible": true,
      "backdrop": false,
      "animation": "scale",
      "title": "Deactivate card?"
    },
    on: {
      "close": function ($event) {
        return _vm.closeModal('deactivate');
      }
    }
  }, [_c('div', {
    staticClass: "deactivate-card"
  }, [_c('div', {
    staticClass: "body"
  }, [_vm._v(" Are you sure you want to delete this card forever? ")]), _c('div', {
    staticClass: "footer"
  }, [_c('elevated-button', {
    attrs: {
      "icon": false,
      "text": "Cancel",
      "variant": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.closeModal('deactivate');
      }
    }
  }), _c('elevated-button', {
    attrs: {
      "icon": false,
      "text": "Yes, pause card",
      "variant": "danger"
    },
    on: {
      "click": _vm.deactivateCard
    }
  })], 1)])]), _c('modal', {
    attrs: {
      "show": _vm.showModal.freeze,
      "dismissible": true,
      "backdrop": false,
      "animation": "scale",
      "title": "Freeze card?"
    },
    on: {
      "close": function ($event) {
        return _vm.closeModal('freeze');
      }
    }
  }, [_c('div', {
    staticClass: "deactivate-card"
  }, [_c('div', {
    staticClass: "body"
  }, [_vm._v(" Are you sure you want to pause transactions from happening on this card? ")]), _c('div', {
    staticClass: "footer"
  }, [_c('elevated-button', {
    attrs: {
      "icon": false,
      "text": "Cancel",
      "variant": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.closeModal('freeze');
      }
    }
  }), _c('elevated-button', {
    attrs: {
      "icon": false,
      "text": "Yes, pause card"
    },
    on: {
      "click": _vm.deactivateCard
    }
  })], 1)])])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }