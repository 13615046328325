<template>
  <div
    :class="{ reverse }"
    class="card-button"
    role="button"
    tabindex="0"
    @click="$emit('click')"
    @keypress.enter="$emit('click')"
  >
    <div class="card-details">
      <h3 class="card-title">
        {{ title }}
      </h3>
      <p class="card-description">
        {{ description }}
      </p>
    </div>
    <div class="card-icon">
      <slot name="icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FeatureButton",
  props: {
    background: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$el.style.setProperty("--background", this.background);
  },
};
</script>

<style lang="scss" scoped>
.card-button {
  box-shadow: 8px 8px #222, 8px 8px 0px 2px black;
  border: 2px solid #000000;
  border-radius: 8px;
  padding: 22px 24px;
  transition: 0.3s all ease-out;
  cursor: pointer;
  display: grid;
  grid-template-areas: "text icon";
  grid-template-columns: 1fr 44px;
  grid-column-gap: 31px;
  /* min-height: 104px; */

  &.reverse {
    grid-template-areas: "icon text";
    grid-template-columns: 44px 1fr;
  }

  &:hover,
  &:focus {
    box-shadow: 8px 8px var(--background), 8px 8px 0px 2px black;
  }

  &:focus-visible {
    outline: none;
  }

  .card {
    &-details {
      grid-area: text;
    }

    &-title {
      font-size: 1.6rem;
      letter-spacing: -0.8px;
      margin-bottom: 8px;
    }

    &-description {
      font-size: 1.2rem;
    }

    &-icon {
      grid-area: icon;
      width: 44px;
      height: 44px;
      border: 2px solid #000000;
      border-radius: 50%;
      background: var(--background);
      display: grid;
      place-items: center;

      * {
        max-width: 50%;
        max-height: 40%;
      }
    }
  }

  .feature-text {
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 8px;
  }
}
</style>
