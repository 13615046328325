const debits = [
  "withdraw",
  "airtime",
  "savings",
  "withdraw_crypto",
  "virtual_card_debit",
  "withdraw_virtual_card",
  "send_crypto",
  "crossboarder_payout"
];

const credits = [
  "wallet_fund",
  "fund",
  "virtual_card_credit",
  "receive_crypto",
  "virtual_card_failed_txn",
  "flux_transfer"
];

export default {
  credits,
  debits
};
