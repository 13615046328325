var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "features"
  }, _vm._l(_vm.features, function (f, index) {
    return _c('feature-button', {
      key: index,
      attrs: {
        "background": f.background,
        "title": f.title
      },
      on: {
        "click": function ($event) {
          f.onTap ? f.onTap() : null;
        }
      },
      scopedSlots: _vm._u([{
        key: "icon",
        fn: function () {
          return [_c('img', {
            attrs: {
              "src": f.src,
              "alt": f.alt
            }
          })];
        },
        proxy: true
      }], null, true)
    });
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }