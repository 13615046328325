<template>
  <li
    class="portfolio-card"
    role="button"
    :data-disabled="disabled"
    @click="$emit('click')"
    @keypress.enter="$emit('click')"
  >
    <div class="portfolio-image">
      <crypto-img :asset="asset" />
    </div>
    <div class="portfolio-details">
      <h3>{{ asset }}</h3>
      <p>{{ showAmount ? balance : "" }} {{ asset }} ({{ network }})</p>
    </div>
    <div v-if="showAmount" class="portfolio-amount">
      <h4>
        <template>
          {{ toCurrency((converted_balance || 0), "USD") }}
        </template>
        <!-- <template v-else> 0 </template> -->
      </h4>
      <p v-if="growth" class="growth" :class="status">
        {{ growth }}
      </p>
    </div>
  </li>
</template>

<script>
import { Polarity } from "@/utils";
import toCurrency from "../../../plugins/filters/toCurrency";
export default {
  methods: { toCurrency },
  props: {
    growth: {
      type: String,
      validator(value) {
        return value.charAt(0) === "+" || value.charAt(0) === "-";
      },
    },
    asset: {
      type: String,
      required: false,
    },
    balance: {
      type: [String, Number],
      required: true,
    },
    converted_balance: {
      type: undefined,
    },
    network: {
      type: String,
      required: true,
    },
    "show-amount": {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      return Polarity.checkPolarity(this.growth);
    },
  },
};
</script>

<style lang="scss" scoped>
.portfolio {
  &-card {
    display: flex;
    align-items: center;
    height: fit-content;

    &[data-disabled="true"] {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &-image {
    width: 32px;
    height: 32px;
    border: 2px solid #000;
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin-right: 14px;

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      border-radius: 50%;
    }
  }

  &-details {
    h3 {
      font-size: 1.4rem;
    }

    p {
      font-size: 1.2rem;
      margin-top: 5px;
    }
  }

  &-amount {
    margin-left: auto;
    h4 {
      font-size: 1.4rem;
      font-weight: bold;
    }

    .growth {
      text-align: right;
      font-size: 1.2rem;
      margin-top: 5px;

      &.positive {
        color: #47c479;
      }

      &.neutral {
        color: #4187ff;
      }

      &.negative {
        color: #ff5e5e;
      }
    }
  }
}
</style>
