var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "transaction-card"
  }, [_c('div', {
    staticClass: "transaction-image"
  }, [_c('skeleton-box', {
    attrs: {
      "height": "32px",
      "width": "32px",
      "borderRadius": "50%"
    }
  })], 1), _c('div', {
    staticClass: "transaction-details"
  }, [_c('skeleton-box', {
    attrs: {
      "marginBottom": "3px"
    }
  }), _c('skeleton-box')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }