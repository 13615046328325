import { errorHandler, server } from "@/utils";
import toast from "vue-m-message";

export default {
  async get() {
    try {
      const { status, data: response } = await server.get("/virtual_cards/");

      if (status === 200) {
        return response.results;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async create({ card_name, card_style, action = "CREATE" }) {
    /**
     action is [CREATE] if user already has an existing Virtual Card on record.

     *If it is the first time applying. Use action [APPLY].
     */
    try {
      const { data: response } = await server.post("/virtual_cards/v2/apply/", {
        card_name,
        card_style,
        action,
      });

      if (response.success) {
        toast.success(response.msg);
        return response;
      } else {
        toast.error(response.msg);
        throw new Error(response.msg);
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async changeStatus(card_ref, action = "FREEZE") {
    try {
      const { status, data: response } = await server.post(
        "/virtual_cards/status/",
        {
          card_ref,
          action,
        }
      );

      if (status === 200) {
        return response.results;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async topup({ card_ref, amount, source_currency }) {
    try {
      const { status, data: response } = await server.post(
        "/virtual_cards/v2/topup/",
        {
          card_ref,
          amount,
          source_currency,
        }
      );

      if (status === 200) {
        toast.succsess(response.msg);
        return response.data;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
};
