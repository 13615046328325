var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "virtual-cards"
  }, [!_vm.showEmptyState ? [_c('section-header', {
    attrs: {
      "title": "Virtual Card",
      "action": "More Actions",
      "onTap": _vm.moreActions
    }
  }), _c('cards-slide', {
    attrs: {
      "cards": _vm.cards
    },
    model: {
      value: _vm.index,
      callback: function ($$v) {
        _vm.index = $$v;
      },
      expression: "index"
    }
  }), _c('ul', {
    staticClass: "actions"
  }, _vm._l(_vm.actions, function (action, index) {
    return _c('li', {
      key: index,
      staticClass: "action",
      attrs: {
        "role": "button"
      },
      on: {
        "click": function ($event) {
          action.onTap ? action.onTap() : null;
        }
      }
    }, [_c('i', {
      staticClass: "icon"
    }, [action.icon ? _c('img', {
      attrs: {
        "src": action.icon,
        "alt": action
      }
    }) : _vm._e()]), _c('p', [_vm._v(_vm._s(action.title))])]);
  }), 0), _c('card-options', {
    attrs: {
      "card_ref": _vm.activeCard ? _vm.activeCard.ref : 1
    }
  })] : [_c('div', {
    staticClass: "empty"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/cards/empty_state.png"),
      "alt": "Flux card designs"
    }
  }), _c('h3', [_vm._v("Flux Virtual Cards")]), _c('p', [_vm._v("Shop anywhere online easily and securely.")]), _c('text-button', {
    attrs: {
      "text": "Create Virtual Card"
    },
    on: {
      "click": _vm.createCard
    }
  })], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }