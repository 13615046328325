<template>
  <div id="recent-beneficiaries">
    <section-header :title="title" :action="action" :onTap="viewAll" />

    <div class="beneficiaries-grid">
      <template v-if="fetching">
        <li v-for="n in 5" :key="n" class="beneficiary-item">
          <skeleton-box borderRadius="8px" height="130px" width="99px" />
        </li>
      </template>
      <template>
        <wallet-beneficiary
          v-for="beneficiary in beneficiaries"
          :key="beneficiary.address"
          :avatar="beneficiary.pic"
          :name="beneficiary.name"
          :address="address(beneficiary)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import WalletBeneficiary from "./beneficiary.vue";
import { SectionHeader } from "@/components";
import { Beneficiaries } from "@/api";
export default {
  name: "WalletBeneficiaries",
  props: {
    title: {
      type: String,
      default: "Recent Beneficiaries",
    },
    action: {
      type: String,
      default: "See All",
    },
  },
  components: {
    WalletBeneficiary,
    SectionHeader,
  },
  data() {
    return {
      beneficiaries: [],
      fetching: true,
    };
  },
  methods: {
    viewAll() {
      this.$router.push({
        name: "Beneficiaries",
      });
    },
    address(beneficiary) {
      return beneficiary.flux_tag ?? beneficiary.flux_id ?? "";
    },
    async getBeneficiaries() {
      this.fetching = true;
      try {
        let response = await Beneficiaries.getBanks();
        this.beneficiaries = response.results.map((b) => {
          return {
            avatar: "",
            name: b.custom_name ?? b.acct_name,
            flux_tag: b.bank_name,
            flux_id: b.account_no,
          };
        });

        response = await Beneficiaries.get();
        this.beneficiaries.push(...response.results.map((b) => b.receiver));
      } finally {
        this.fetching = false;
      }
    },
  },
  mounted() {
    this.getBeneficiaries();
  },
};
</script>

<style lang="scss" scoped>
#recent-beneficiaries {
  border-top: 1px solid black;
  padding: 24px 28px;

  .beneficiaries-grid {
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 15.8px; */
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    @include hide-scrollbar();

    > * {
      margin-right: 15.8px;
      min-width: 100px;
    }
  }
}
</style>
