<template>
  <div class="balance">
    <ul class="balance-heading">
      <li
        v-for="option in options"
        :key="option.title"
        role="button"
        :class="{ active: currency === option.option }"
        @click="currency = option.option"
      >
        {{ option.title }}
      </li>
    </ul>
    <div class="balance-available">
      <div class="div">
        <h2>
          <template v-if="!hidden">
            {{ balance.available_balance | toCurrency(currency) }}
          </template>
          <template v-else> ******<span>.**</span> </template>
        </h2>
      </div>
      <button class="icon" @click="hidden = !hidden">
        <transition name="fade" mode="out-in">
          <img
            v-if="hidden"
            :key="hidden.toString()"
            src="@/assets/icons/eye-open.svg"
            alt=""
          />
          <img v-else src="@/assets/icons/eye-closed.svg" alt="" />
        </transition>
      </button>
    </div>
    <div class="balance-locked">
      <img
        class="icon"
        src="@/assets/icons/feather-lock.svg"
        alt="A padlock icon"
      />

      <p>
        <template v-if="!hidden">
          {{ balance.ledger_balance || 0 | toCurrency(currency) }}
        </template>
        <template v-else> ******<span>.**</span> </template>
      </p>
    </div>

    <ul class="balance-actions">
      <li
        v-for="(action, index) in actions"
        :key="index"
        class="action"
        role="button"
        @click="action.onTap ? action.onTap() : null"
      >
        <i class="icon" :style="{ backgroundColor: action.background }">
          <img v-if="action.src" :src="action.src" :alt="action" />
        </i>
        <p>{{ action.title }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WalletBalance",

  data() {
    return {
      currency: "NGN",
      options: [
        {
          option: "NGN",
          title: "Naira",
        },
        {
          option: "EUR",
          title: "Euro",
        },
        {
          option: "USD",
          title: "Dollar",
        },
      ],
      actions: [
        {
          background: "#FFC400",
          title: "Add Cash",
          src: require("@/assets/icons/plus.svg"),
          alt: "plus icon",
          onTap: () => {
            this.$router.push({ name: "AddCash" });
          },
        },
        {
          background: "#47C479",
          title: "Safe",
          src: require("@/assets/icons/safe.svg"),
          alt: "piggy bank icon",
          onTap: () => {
            this.$router.push({ name: "Safe" });
          },
        },
        {
          background: "#4187FF",
          title: "Send Cash",
          src: require("@/assets/icons/send.svg"),
          alt: "airplane icon",
          onTap: () => {
            this.$router.push({ name: "SendCash" });
          },
        },
      ],
    };
  },
  computed: {
    hidden: {
      get() {
        return this.$store.getters["user/hideBalance"];
      },
      set(value) {
        this.$store.commit("user/setHideBalance", value);
      },
    },
    ...mapGetters({ user: "user/user", savings: "user/savings" }),
    balance() {
      if (this.currency === "NGN") {
        return {
          available_balance: this.user.split_balance.available_balance,
          ledger_balance: this.user.split_balance.ledger_balance,
        };
      } else {
        const balance = this.savings?.find(
          (saving) => saving?.currency === this.currency
        );

        return {
          available_balance: balance?.balance ?? 0,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.balance {
  margin: 10px 28px 0;
  box-shadow: 8px 8px #222, 8px 8px 0px 2px black;
  border: 2px solid #000000;
  padding: 28px 35px;
  border-radius: 8px;

  &-heading {
    font-size: 1.1rem;
    font-weight: 300;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-transform: uppercase;

    li {
      padding: 6px 12px;
      border-radius: 8px;
      border: 2px solid transparent;
      color: #222;
      transition: 0.2s all ease;

      &.active {
        background: #222;
        border-color: #000;
        color: #fff;
      }
    }
  }

  &-available {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      letter-spacing: -1px;
      font-size: 2.2rem;
    }

    span {
      color: #222;
      font-size: 60%;
    }

    .icon {
      width: 28px;
      height: 28px;
      background: #222;
      border-radius: 50%;
      margin-left: 9px;
      display: block;
      display: grid;
      place-items: center;
      cursor: pointer;
      border: 2px solid black;

      * {
        width: 50%;
      }
    }
  }

  &-locked {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 9px;

    p {
      letter-spacing: -1px;
      font-size: 1.4rem;
    }

    span {
      color: #222;
      font-size: 60%;
    }

    .icon {
      height: 11.9px;
      margin-right: 9px;
    }
  }

  &-actions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 35px;
    width: 100%;

    .action {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 2px solid #000;
      background: map-get($colours, purple);
      display: grid;
      place-items: center;
      margin-bottom: 8px;

      img {
        max-width: 40%;
        max-height: 40%;
        filter: brightness(0);
      }
    }
  }
}
</style>
