<template>
  <div class="transaction-card">
    <div class="transaction-image">
      <skeleton-box height="32px" width="32px" borderRadius="50%" />
    </div>
    <div class="transaction-details">
      <skeleton-box marginBottom="3px" />
      <skeleton-box />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.transaction {
  &-card {
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-gap: 14px;
  }

  &-image {
    /* margin-right: 14px; */
  }
}
</style>
