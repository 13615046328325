<template>
  <ul class="cards-list">
    <transition :name="transitionName">
      <li :key="active" class="card">
        <img
          lazy-load
          class="card-design"
          :src="require(`@/assets/images/cards/${card.card_style}.png`)"
          alt=""
        />

        <div class="card-details">
          <div class="title">{{ card.custom_name }}</div>
          <div class="balance">
            <p v-if="showBalance" class="amount">
              <span>{{ currencies[card.currency].symbol }}</span
              >{{ (card.balance / 100).toLocaleString() }}
            </p>
            <p v-else class="amount"><span>*</span>****</p>

            <button class="icon" @click="showBalance = !showBalance">
              <transition name="fade" mode="out-in">
                <img
                  v-if="showBalance"
                  key="1"
                  src="@/assets/icons/eye-closed.svg"
                  alt=""
                />
                <img v-else src="@/assets/icons/eye-open.svg" alt="" />
              </transition>
            </button>
          </div>
        </div>
      </li>
    </transition>
    <div v-if="controls" class="controls">
      <button v-if="value !== 0" class="prev" @click="move('prev')">
        <img src="@/assets/icons/arrow-left.svg" alt="" />
      </button>
      <button
        v-if="value !== cards.length - 1"
        class="next"
        @click="move('next')"
      >
        <img src="@/assets/icons/arrow-right.svg" alt="" />
      </button>
    </div>
    <div class="shadow"></div>
  </ul>
</template>

<script>
import { currencies } from "@/constants";
export default {
  name: "CardsSlide",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    cards: {
      type: Array,
    },
  },
  data() {
    return {
      showBalance: true,
      active: this.value,
      transitionName: "slide-right",
      currencies,
    };
  },
  methods: {
    move(direction) {
      switch (direction) {
        case "prev":
          this.active--;
          this.transitionName = "slide-left";
          break;
        default:
          this.active++;
          this.transitionName = "slide-right";
          break;
      }
      this.$emit("input", this.active);
    },
  },
  computed: {
    card() {
      return this.cards[this.value];
    },
  },
};
</script>

<style lang="scss" scoped>
.cards-list {
  position: relative;
  margin-top: 10px;
}

.card {
  width: 280px * 0.9;
  height: 150px * 0.9;
  border-radius: 16px;
  border: 2px solid #000;
  position: relative;
  overflow: hidden;
  margin-inline: auto;

  &-details {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 16px;
    color: #fff;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    place-items: center;

    .title {
      background: #00000066 0% 0% no-repeat padding-box;
      border-radius: 19px;
      width: fit-content;
      height: fit-content;
      padding: 6px 12px;
      font-size: 1.2rem;
    }

    .balance {
      border-radius: 19px;
      width: fit-content;
      height: fit-content;
      font-size: 2.2rem;
      padding: 6px 12px;
      background: #00000099 0% 0% no-repeat padding-box;
      display: inline-flex;
      align-items: center;

      span {
        font-size: 60%;
      }

      .icon {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        margin-left: 12px;
        background: rgba(grey, 0.4);

        img {
          width: 50%;
        }
      }
    }
  }

  &-design {
    object-fit: cover;
    object-position: center;
    transform: scale(1.1);
  }
}

.shadow {
  position: absolute;
  display: block;
  height: 20px;
  border-bottom-left-radius: 23px;
  border-bottom-right-radius: 23px;
  border: 2px solid #000;
  border-top: none;
  background: #222222;
  width: 234px * 0.9;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
}

.controls {
  button {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 2px solid #000;
    background: #222;
    display: grid;
    place-items: center;
    position: absolute;
    top: 50%;

    img {
      width: 50%;
    }

    &.prev {
      transform: translate(-50%, -50%);
    }

    &.next {
      right: 0;
      transform: translate(50%, -50%);
    }
  }
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.3s ease-out;
}

.slide-left-enter,
.slide-left-leave-to,
.slide-right-enter,
.slide-right-leave-to {
  position: absolute;
  opacity: 0;
}

.slide-left-enter,
.slide-right-leave-to {
  transform: translateX(100%);
}

.slide-left-leave-to,
.slide-right-enter {
  transform: translateX(-100%);
}
</style>
