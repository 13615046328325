var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-button",
    class: {
      reverse: _vm.reverse
    },
    attrs: {
      "role": "button",
      "tabindex": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      },
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.$emit('click');
      }
    }
  }, [_c('div', {
    staticClass: "card-details"
  }, [_c('h3', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('p', {
    staticClass: "card-description"
  }, [_vm._v(" " + _vm._s(_vm.description) + " ")])]), _c('div', {
    staticClass: "card-icon"
  }, [_vm._t("icon")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }