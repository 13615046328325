var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.key,
    attrs: {
      "id": "wallet"
    }
  }, [_vm.isMobile ? [_c('profile-bar'), _c('wallet-balance'), _c('wallet-features'), _c('wallet-history', {
    attrs: {
      "height": "100%"
    }
  })] : _c('div', {
    staticClass: "wallet"
  }, [[_c('div', {
    staticClass: "left"
  }, [_c('profile-bar'), _c('wallet-balance'), _c('wallet-features'), _c('wallet-beneficiaries')], 1), _c('div', {
    staticClass: "right"
  }, [_c('wallet-history', {
    attrs: {
      "height": "40%"
    }
  }), _c('hr'), _c('wallet-cards', {
    attrs: {
      "height": "60%"
    }
  })], 1)]], 2), _c('transition', {
    attrs: {
      "name": "slide-out"
    }
  }, [_vm.childRoute ? _c('aside', {
    class: {
      mobile: _vm.isMobile
    },
    attrs: {
      "id": "drawer"
    }
  }, [_c('div', {
    staticClass: "drawer-body"
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_c('router-view', {
    attrs: {
      "name": "sidebar"
    }
  })], 1)], 1)]) : _vm._e()])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }