var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "recent-beneficiaries"
    }
  }, [_c('section-header', {
    attrs: {
      "title": _vm.title,
      "action": _vm.action,
      "onTap": _vm.viewAll
    }
  }), _c('div', {
    staticClass: "beneficiaries-grid"
  }, [_vm.fetching ? _vm._l(5, function (n) {
    return _c('li', {
      key: n,
      staticClass: "beneficiary-item"
    }, [_c('skeleton-box', {
      attrs: {
        "borderRadius": "8px",
        "height": "130px",
        "width": "99px"
      }
    })], 1);
  }) : _vm._e(), _vm._l(_vm.beneficiaries, function (beneficiary) {
    return _c('wallet-beneficiary', {
      key: beneficiary.address,
      attrs: {
        "avatar": beneficiary.pic,
        "name": beneficiary.name,
        "address": _vm.address(beneficiary)
      }
    });
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }