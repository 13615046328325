<template>
  <container id="app" :class="{ desktop: !isMobile, mobile: isMobile }">
    <header>
      <router-link to="/auth/login" custom v-slot="{ navigate }">
        <button
          role="button"
          id="logo"
          @click="navigate"
          @keypress.enter="navigate"
        >
          <img src="@/assets/icons/flux.svg" alt="flux logo" />
          <span class="badge">BANK</span>
        </button>
      </router-link>
    </header>
    <div id="flux-layout">
      <nav id="side-panel" v-if="!isMobile">
        <ul class="nav-list">
          <router-link to="/contact" custom v-slot="{ navigate }">
            <li
              class="nav-item"
              data-link="contact"
              @click="navigate"
              @keypress.enter="navigate"
              role="link"
            >
              <span class="nav-icon">
                <img src="@/assets/icons/contact.svg" alt="" />
              </span>
              <p class="nav-link">
                Need help? <br />
                <span class="subtitle">Contact Support</span>
              </p>
            </li>
          </router-link>
        </ul>
      </nav>
      <div id="main-panel">
        <router-view />
      </div>
    </div>
  </container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Auth",
  computed: {
    ...mapGetters(["isMobile"]),
  },
};
</script>

<style lang="scss" scoped>
#app.mobile {
  padding: 0;
  #side-panel {
    position: fixed;
    z-index: 999999999;
    height: 75px;
    background: #fff;
    box-shadow: 0px -2px 2px #00000029;
    bottom: 0;
    left: 0;
    right: 0;

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 30px;

      .nav-item {
        display: grid;
        place-items: center;
        opacity: 0.5;
        transition: 0.3s all ease;

        .nav-icon {
          img {
            height: 20px;
            margin-bottom: 10px;
          }
        }

        .nav-link {
          font-size: 12px;
        }

        &.active {
          opacity: 1;
        }
      }
    }
  }
}

header {
  display: grid;
  place-items: center;
  padding: 0 20px;
  height: 60px;

  #logo {
    /* height: 40px; */
    max-width: 156px;
    display: flex;
    align-items: center;
    border: 2px solid #000000;
    border-radius: 54px;
    padding: 5px;
    background: white;
    position: relative;
    cursor: pointer;
    z-index: 10;
    transform: translateZ(1px);
    transform-style: preserve-3d;

    &:after {
      content: "";
      right: -6px;
      top: 6px;
      border: 2px solid #000000;
      border-radius: 54px;
      background: #272429;
      width: 100%;
      height: 100%;
      position: absolute;
      transform: translateZ(-1px);
      /* z-index: -11; */
    }

    img {
      height: 24px;
      margin-right: 8px;
    }

    .badge {
      background: map-get($colours, purple);
      border-radius: 34px;
      border: 2px solid #000000;
      padding: 2px 6px;
      /* height: 70%; */
      color: white;
      display: grid;
      place-items: center;
      line-height: 1;
      font-size: 1rem;
    }
  }
}

#app.desktop {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  #flux-layout {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 22px;
    overflow: hidden;
    height: calc(100vh - 70px - 24px);
  }

  #side-panel {
    font-size: 14px;
    height: 100%;

    .nav-list {
      display: flex;
      flex-direction: column;
      height: inherit;
      font-weight: bold;
      color: #2c3e50;

      .nav-item {
        margin: 10px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        transition: 0.3s all ease;

        .nav-icon {
          width: 36px;
          height: 36px;
          border: 2px solid black;
          display: grid;
          border-radius: 50%;
          place-items: center;
          margin-right: 16px;
          transition: 0.3s all ease;

          img {
            width: 50%;
          }
        }

        &:last-of-type {
          margin-top: auto;

          .nav-icon {
            background: #222;
          }

          .subtitle {
            font-size: 1.2rem;
            font-weight: normal;
          }
        }

        &::before {
          content: "";
          opacity: 0;
          width: 10px;
          height: 10px;
          background-color: map-get($map: $colours, $key: purple);
          border: 2px solid #222;
          border-radius: 50%;
          margin-right: 8px;
          transition: 0.2s all ease-out;
        }

        &.active {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  #main-panel {
    height: inherit;
    /* display: grid;
    place-items: center; */
  }
}
</style>
