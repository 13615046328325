<template>
  <div class="balance">
    <template v-if="!isWeb3">
      <div class="balance-heading">
        <skeleton-box v-if="fetching" />
        <dropdown
          v-else
          v-model="network"
          :options="['All Networks BEP20, ERC20', ...networks]"
          :showCircle="true"
        />
      </div>
    </template>
    <h3 class="balance-title">Portfolio Balance</h3>
    <div class="balance-available">
      <skeleton-box v-if="fetching" height="25px" />
      <h2 v-else>
        <template v-if="!hidden">
          <template v-if="isWeb3">
            <span
              >{{ cryptoBalance
              }} {{ isFreighterConnected ? "XLM" : "ETH" }}</span
            >
            <span
              > (${{
                (cryptoBalance * rates[isFreighterConnected ? "XLM" : "ETH"] * 0.01).toFixed(4)
              }})</span
            >
          </template>
          <template v-else>
            {{
              toCurrency(
                filteredTokens.reduce(
                  (partialSum, a) => partialSum + (a.converted_balance || 0),
                  0
                ),
                "USD"
              )
            }}
          </template>
        </template>
        <template v-else> ******<span>.**</span></template>
      </h2>
      <button class="icon" @click="hidden = !hidden">
        <transition name="fade" mode="out-in">
          <img
            v-if="hidden"
            :key="hidden.toString()"
            src="@/assets/icons/eye-open.svg"
            alt=""
          />
          <img v-else src="@/assets/icons/eye-closed.svg" alt="" />
        </transition>
      </button>
    </div>
    <!-- <div class="balance-locked">
      <i class="icon"></i>
      <p>
        <span>₦</span>
        49,335<span>.56</span>
      </p>
    </div> -->
  </div>
</template>

<script>
import { Dropdown } from "@/components";
import { mapGetters } from "vuex";
import toCurrency from "../../../plugins/filters/toCurrency";
import { usePortfolioBalance } from "@/composables";

export default {
  name: "CryptoBalance",
  components: {
    Dropdown,
  },
  setup() {
    const { cryptoBalance, isFreighterConnected, isWeb3 } =
      usePortfolioBalance();

    return {
      cryptoBalance,
      isFreighterConnected,
      isWeb3,
    };
  },
  data() {
    return {
      fetching: true,
      network: "All Networks BEP20, ERC20",
    };
  },
  computed: {
    ...mapGetters({ tokens: "crypto/tokens", rates: "crypto/rates" }),
    hidden: {
      get() {
        return this.$store.getters["user/hideBalance"];
      },
      set(value) {
        this.$store.commit("user/setHideBalance", value);
      },
    },
    filteredTokens() {
      if (this.network !== "All Networks BEP20, ERC20") {
        return this.tokens.filter((token) => token.network === this.network);
      }
      return this.tokens;
    },
    networks() {
      return [...new Set(this.tokens.map((token) => token.network))];
    },
  },
  mounted() {
    this.fetchTokens();
  },
  methods: {
    toCurrency,
    async fetchTokens() {
      this.fetching = true;
      try {
        await this.$store.dispatch("crypto/getTokens");
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.balance {
  margin: 10px 28px 0;
  box-shadow: 8px 8px #222, 8px 8px 0px 2px black;
  border: 2px solid #000000;
  padding: 28px 35px;
  border-radius: 8px;

  &-heading {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-title {
    font-size: 1.2rem;
    text-align: center;
    margin-top: 28px;
    font-weight: normal;
  }

  &-available {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      letter-spacing: -1px;
      font-size: 2.2rem;
    }

    span {
      color: #222;
      font-size: 60%;
    }

    .icon {
      width: 28px;
      height: 28px;
      background: #222;
      border-radius: 50%;
      margin-left: 9px;
      display: block;
      display: grid;
      place-items: center;
      cursor: pointer;
      border: 2px solid black;

      * {
        width: 50%;
      }
    }
  }

  &-locked {
    display: flex;
    align-items: center;
    margin-top: 9px;

    p {
      letter-spacing: -1px;
      font-size: 1.4rem;
    }

    span {
      color: #222;
      font-size: 60%;
    }

    .icon {
      width: 15px;
      height: 15px;
      background: #222;
      border-radius: 50%;
      margin-right: 9px;
      display: block;
      display: grid;
      place-items: center;
      cursor: pointer;
    }
  }
}
</style>
