var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('svg', {
    staticClass: "tick-icon"
  }, [_c('symbol', {
    attrs: {
      "id": "check-tick",
      "viewbox": "0 0 12 10"
    }
  }, [_c('polyline', {
    attrs: {
      "points": "1.5 6 4.5 9 10.5 1"
    }
  })])]), _vm.type === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checked,
      expression: "checked"
    }],
    staticClass: "input-field",
    attrs: {
      "id": _vm.label,
      "type": "checkbox"
    },
    domProps: {
      "value": _vm.option,
      "checked": Array.isArray(_vm.checked) ? _vm._i(_vm.checked, _vm.option) > -1 : _vm.checked
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.checked,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = _vm.option,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.checked = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.checked = $$c;
        }
      }, _vm.onChange]
    }
  }) : _vm.type === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checked,
      expression: "checked"
    }],
    staticClass: "input-field",
    attrs: {
      "id": _vm.label,
      "type": "radio"
    },
    domProps: {
      "value": _vm.option,
      "checked": _vm._q(_vm.checked, _vm.option)
    },
    on: {
      "change": [function ($event) {
        _vm.checked = _vm.option;
      }, _vm.onChange]
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checked,
      expression: "checked"
    }],
    staticClass: "input-field",
    attrs: {
      "id": _vm.label,
      "type": _vm.type
    },
    domProps: {
      "value": _vm.option,
      "value": _vm.checked
    },
    on: {
      "change": _vm.onChange,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.checked = $event.target.value;
      }
    }
  }), _c('label', {
    staticClass: "checkbox",
    attrs: {
      "for": _vm.label
    }
  }, [_c('span', {
    staticClass: "check"
  }, [_c('svg', {
    attrs: {
      "height": "10px",
      "width": "12px"
    }
  }, [_c('use', {
    attrs: {
      "xlink:href": "#check-tick"
    }
  })])]), _vm.showLabel ? _c('span', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }