<template>
  <button @click="$emit('click')" @keypress.enter="$emit('click')">
    <div class="icon">
      <slot name="icon">
        <div class="plus">
          <svg viewBox="0 0 11.86 11.86" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon_feather-plus" data-name="Icon feather-plus">
              <path
                d="M5.93,1v9.86"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
              <path
                d="M1,5.93h9.86"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </g>
          </svg>
        </div>
      </slot>
    </div>
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  font-size: 1.4rem;
  font-weight: 500;
  background: none;
  color: map-get($map: $colours, $key: purple);
  display: inline-flex;

  .icon {
    margin-right: 8px;
    max-width: 18px;
    max-height: 18px;
    overflow: hidden;

    .plus {
      width: 18px;
      height: 18px;
      border: 2px solid map-get($map: $colours, $key: purple);
      background: rgba(map-get($map: $colours, $key: purple), 0.2);
      border-radius: 50%;
      display: grid;
      place-items: center;

      * {
        width: 50%;
        stroke: map-get($map: $colours, $key: purple);
      }
    }
  }
}
</style>
