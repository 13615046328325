import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import payStore from "@/store";
import "@/assets/styles/global-style.scss";
import "swiper/swiper-bundle.css";
import { getToken } from "@/utils/server";
import { directives, filters } from "@/plugins";

// module.exports = "swiper/swiper-bundle.css";
//
import Message from "vue-m-message";
import "vue-m-message/dist/index.css";

Vue.config.productionTip = false;

Vue.component("Container", () => import("@/components/Container"));
Vue.component("DrawerHeader", () =>
  import("@/components/Drawer/header")
);
Vue.component("SkeletonBox", () => import("@/components/Skeleton"));
Vue.component("CryptoImg", () => import("@/components/CryptoImg"));
Vue.use(Message, { name: "toast" });
Message.globals.options.zIndex = 9999999999;

Object.entries(directives).forEach(([directiveName, directiveFunction]) => {
  Vue.directive(directiveName, directiveFunction);
});

Object.entries(filters).forEach(([filterName, filterFunction]) => {
  Vue.filter(filterName, filterFunction);
});

new Vue({
  router,
  store: payStore,
  render: (h) => h(App),
  async beforeCreate() {
    await payStore.dispatch("checkScreenSize");

    const token = await getToken();
    if (!token) {
      await router.push({ name: "Login" });
    } else {
      await payStore.dispatch("crypto/getRatesContinous");
    }
  },
}).$mount("#app");
