<template>
  <Auth v-if="isAuth" />
  <Dashboard v-else />
</template>

<script>
import { Dashboard, Auth } from "@/layout";
export default {
  components: {
    Dashboard,
    Auth,
  },
  computed: {
    isAuth() {
      return this.$route.path.startsWith("/auth");
    },
  },
};
</script>
