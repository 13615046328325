<template>
  <div
    class="feature-button"
    role="button"
    tabindex="0"
    @click="$emit('click')"
    @keypress.enter="$emit('click')"
  >
    <div class="feature-icon">
      <slot name="icon" />
    </div>
    <h3 class="feature-text">
      {{ title }}
    </h3>
  </div>
</template>

<script>
export default {
  name: "FeatureButton",
  props: {
    background: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$el.style.setProperty("--background", this.background);
  },
};
</script>

<style lang="scss" scoped>
.feature-button {
  box-shadow: 8px 8px #222, 8px 8px 0px 2px black;
  border: 2px solid #000000;
  border-radius: 8px;
  padding: 16.5px 20px;
  height: 87px;
  transition: 0.3s all ease-out;
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: 8px 8px var(--background), 8px 8px 0px 2px black;
  }

  &:focus-visible {
    outline: none;
  }

  .feature-icon {
    width: 32px;
    height: 32px;
    border: 2px solid #000000;
    border-radius: 50%;
    background: var(--background);
    display: grid;
    place-items: center;

    * {
      width: Max(50%, 10px);
    }
  }

  .feature-text {
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 8px;
  }
}
</style>
