import toast from "vue-m-message";
import { errorHandler, server } from "@/utils";

export default {
  async create({ amount, currency, lock_expires }) {
    try {
      const { status, data: response } = await server.post("/savings/save/", {
        amount,
        currency,
        lock_expires,
      });

      if (status === 200) {
        toast.success(response.msg);
        return response;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async get() {
    try {
      const { status, data: response } = await server.get("/savings/");

      if (status === 200) {
        return response;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
};
