import toast from "vue-m-message";
import { capitalise, errorHandler, server } from "@/utils";

export default {
  async create({ amount, currency, lock_expires }) {
    try {
      const { status, data: response } = await server.post("/savings/save/", {
        amount,
        currency,
        lock_expires
      });

      if (status === 200) {
        toast.success(response.msg);
        return response;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async get(category) {
    try {
      const { status, data: response } = await server.get("/bills/v2/categories/", {
        params: {
          category
        }
      });

      if (status === 200) {
        return response.results;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async validateService({ biller_code, item_code, customer_id }) {
    try {
      const { status, data: response } = await server.post("/bills/v2/validate/", {
        biller_code,
        item_code,
        customer: customer_id
      });

      if (status === 200) {
        if (response.data) {
          return response.data;
        } else {
          toast.error(capitalise(response.msg));
        }
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async pay({ biller_name, category, customer_id, amount, crypto_wallet }) {
    try {
      const { data: response } = await server.post("/bills/v2/pay/", {
        biller_name,
        category: category.toUpperCase(),
        customer: customer_id,
        amount,
        crypto_wallet
      });

      // if (status === 200) {
      if (response.success) {
        toast.success(response.msg);
        return response.data;
      } else {
        throw new Error(response.msg);
      }
      // }
    } catch (response) {
      errorHandler(response);
    }
  },
  async topup({ amount, receiver }) {
    try {
      const { data: response } = await server.post("/others/v2/airtime/", {
        receiver,
        amount
      });

      // if (status === 200) {
      if (!response.error) {
        toast.success(response.message);
        return response.message;
      } else {
        throw new Error(response.message);
      }
      // }
    } catch (response) {
      errorHandler(response);
    }
  },
  async getBillCategories() {
    try {
      const { status, data: response } = await server.get("/bills/v2/categories/");

      if (status === 200) {
        return {
          success: true,
          response
        };
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
};
