<template>
  <div class="form-input">
    <div class="input">
      <input
        ref="input"
        :data-placeholder="$attrs.placeholder ? true : false"
        :value="value"
        v-bind="$attrs"
        @blur="$emit('blur')"
        @input="$emit('input', $refs.input.value)"
      />
      <label for="">{{ label }}</label>
    </div>
    <slot name="icon" />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: undefined,
    },
  },
  mounted() {
    if (!this.$attrs.placeholder) {
      this.$el.querySelector("input").setAttribute("placeholder", " ");
    }
  },
};
</script>

<style lang="scss" scoped>
.form-input {
  height: 56px;
  min-height: 56px;
  border-radius: 8px;
  border: 2px solid #000000;
  padding-inline: 20px;
  display: grid;
  grid-template-columns: 1fr 26px;
  background: inherit;

  &[type="date"] {
    grid-template-columns: unset;
  }

  .input {
    position: relative;

    input {
      outline: none;
      border: none;
      height: 100%;
      width: 100%;
      font-size: 1.4rem;
      font-weight: 500;

      & + label {
        position: absolute;
        font-size: 1.4rem;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        transition: 0.3s all ease;
        pointer-events: none;
      }

      &:active,
      &:focus,
      &:not(:placeholder-shown),
      &[data-placeholder] {
        & + label {
          top: 0;
          left: -10px;
          font-size: 1.2rem;
          padding-inline: 4px;
          background: #fff;
        }
      }
    }
  }
}
</style>
