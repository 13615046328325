export default {
  namespaced: true,
  state: () => ({
    transferType: null,
  }),
  mutations: {
    SET_TRANSFER_TYPE(state, transferType) {
      state.transferType = transferType;
    },
  },
  getters: {
    coins(state) {
      return state.coins;
    },
    transferType(state) {
      return state.transferType;
    },
  },
};
