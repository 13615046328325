<template>
  <div class="beneficiary">
    <div class="beneficiary-image">
      <img v-if="avatar" :src="avatar" alt="" />
      <p v-else>{{ name.charAt(0).toUpperCase() }}</p>
    </div>
    <h4 class="beneficiary-name">
      {{ name }}
    </h4>
    <p class="beneficiary-address">{{ address }}</p>
  </div>
</template>

<script>
export default {
  name: "CryptoBeneficiary",
  props: {
    avatar: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    address: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.beneficiary {
  padding: 12px;
  border-radius: 8px;
  border: 2px solid #000000;
  cursor: pointer;
  /* overflow: hidden; */

  &-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid #000000;
    display: grid;
    place-items: center;
    margin-bottom: 8px;
    overflow: hidden;

    p {
      font-weight: 700;
    }
  }

  &-name {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: unset;
  }

  &-address {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
