const isPositive = (value) => {
  return value.charAt(0) === "+";
};

const isNegative = (value) => {
  return value.charAt(0) === "-";
};

const checkPolarity = (value) => {
  if (isPositive(value)) return "positive";
  else if (isNegative(value)) return "negative";
  else return "neutral";
};

export default {
  checkPolarity,
  isNegative,
  isPositive,
};
