<template>
  <div class="transaction-card">
    <div class="transaction-details">
      <h3>{{ action }}</h3>
      <p>{{ date }}</p>
    </div>
    <div class="transaction-amount">
      <h4>{{ amount }}</h4>
      <div v-if="status" class="status" :class="status"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      // validator(value) {
      //   return ["completed", "pending", "failed"].includes(value);
      // },
    },
    image: {
      type: String,
      required: true,
    },
    amount: {
      type: [Number, String],
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    action: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.transaction {
  &-card {
    display: flex;
    align-items: center;
  }

  &-image {
    width: 32px;
    height: 32px;
    border: 2px solid #000;
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin-right: 14px;

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      border-radius: 50%;
    }
  }

  &-details {
    h3 {
      font-size: 1.4rem;
    }

    p {
      font-size: 1.2rem;
      font-weight: 300;
    }
  }

  &-amount {
    margin-left: auto;
    h4 {
      font-size: 1.4rem;
      font-weight: bold;
    }

    .status {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-top: 8px;
      margin-left: auto;
      background: #47c47999;

      /* &.completed {
      } */

      &.pending {
        background: #4187ff99;
      }

      &.failed {
        background: #ff5e5e99;
      }
    }
  }
}
</style>
