<template>
  <div>
    <svg class="tick-icon">
      <symbol id="check-tick" viewbox="0 0 12 10">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
      </symbol>
    </svg>

    <input
      :id="label"
      v-model="checked"
      :type="type"
      :value="option"
      class="input-field"
      @change="onChange"
    />
    <label :for="label" class="checkbox"
      ><span class="check">
        <svg height="10px" width="12px">
          <use xlink:href="#check-tick"></use>
        </svg> </span
      ><span v-if="showLabel" class="label">{{ label }}</span></label
    >
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "checkbox",
    },
    label: {
      type: String,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    option: {
      type: undefined,
    },
    value: {
      type: undefined,
    },
  },
  data() {
    return {
      checkedProxy: false,
    };
  },
  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(val) {
        this.checkedProxy = val;
      },
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.checkedProxy);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;

  span {
    float: left;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
    font-size: 1.4rem;
  }
}

.input-field {
  display: none;
}

.check {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transform: scale(1);
  border: 2px solid #000;
  transition: all 0.2s ease;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    fill: none;
    stroke: #000;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate(-50%, -50%);
  }
}

.label {
  padding-left: 12px;
}

.input-field:checked + .checkbox .check {
  background: map-get($colours, purple);
  animation: myAnimation 0.4s ease;
}

.input-field:checked + .checkbox .check svg {
  stroke-dashoffset: 0;
}

.tick-icon {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

@media screen and (max-width: 650px) {
  .checkbox {
    width: 100%;
    margin-bottom: 4px;
    display: inline-block;
  }
}

@keyframes myAnimation {
  50% {
    transform: scale(0.9);
  }
}
</style>
