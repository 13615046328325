import { errorHandler, server } from "@/utils";

export default {
  async getWallets() {
    try {
      const { status, data: response } = await server.get(
        "/crypto/v3/wallets/"
      );

      if (status === 200) {
        return response.results;
      }
      // eslint-disable-next-line no-unreachable
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async getRates() {
    try {
      const { status, data: response } = await server.get("/crypto/rates/v2/");

      if (status === 200) {
        return response.results;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async buy({ send = "NGN", receive, amount }) {
    try {
      const { status, data: response } = await server.post(
        "/exchange/crypto/buy_p2p/",
        {
          send,
          receive,
          amount,
        }
      );

      if (status === 200) {
        return response;
      }
      // eslint-disable-next-line no-unreachable
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async sell({ send, receive, destination, amount, bank, network }) {
    try {
      const { status, data: response } = await server.post(
        "/exchange/crypto/sell_p2p/",
        {
          send,
          receive,
          destination,
          amount,
          bank,
          network,
        }
      );

      if (status === 200) {
        return {
          response,
          success: true
        };
      }
      // eslint-disable-next-line no-unreachable
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async send({ amount, asset, address, wallet_ref, receiver, saved_address }) {
    try {
      if (address && (receiver || saved_address)) {
        throw Error(
          "Please do not include a receiver or a saved_address when passing address"
        );
      }

      if (receiver && saved_address) {
        throw Error("Please do not include both receiver and a saved_address");
      }
      const { status, data: response } = await server.post("/crypto/send/v3/", {
        amount,
        asset,
        address,
        wallet_ref,
        receiver,
        saved_address,
        gifted: false,
        anonymous: false,
      });

      if (status === 200) {
        return response;
      }
      // eslint-disable-next-line no-unreachable
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async getSendFee({ asset, network }) {
    try {
      const { status, data: response } = await server.get(
        "/crypto/v3/send/fee/"
      );

      if (status === 200) {
        if (asset && network) {
          return response.results.find(
            (t) => t.asset === asset && t.network === network
          );
        }
        return response.results;
      }
      // eslint-disable-next-line no-unreachable
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async getTransactFee({ kind, send, receive, destination, amount }) {
    if (kind === "BUY") send = "NGN";
    try {
      const { status, data: response } = await server.get(
        "/exchange/crypto/rate/",
        {
          params: {
            kind,
            send,
            receive,
            destination,
            amount,
          },
        }
      );

      if (status === 200) {
        return response.data;
      }
      // eslint-disable-next-line no-unreachable
    } catch ({ response }) {
      errorHandler(response);
    }
  },
};
