import { errorHandler, server } from "@/utils";

export default {
  async getAd(ref) {
    try {
      const { status, data: response } = await server.get(
        `/p2p_dex/advert/${ref}/`
      );

      if (status === 200) {
        return {
          success: true,
          response,
        };
      }
    } catch ({ response }) {
      errorHandler(response);
    }

    return {
      success: false,
    };
  },
  async getAllAds() {
    try {
      const { status, data: response } = await server.get(
        "/p2p_dex/public_ads/"
      );

      if (status === 200) {
        return {
          success: true,
          response: response.results,
        };
      }
    } catch ({ response }) {
      errorHandler(response);
    }

    return {
      success: false,
    };
  },
  async getMyAds() {
    try {
      const { status, data: response } = await server.get("/p2p_dex/my_ads/");
      console.log(status, response, 'jake');

      if (status === 200) {
        return {
          success: true,
          response: response.results,
        };
      }
    } catch ({ response }) {
      errorHandler(response);
    }

    return {
      success: false,
    };
  },
  async createP2PAd(payload) {
    try {
      const { status, data: response } = await server.post("/p2p_dex/advert/", {
        ...payload,
        fiat: "NGN",
        total_amount: Number(payload.total_amount),
        price_per_usd: Number(payload.price_per_usd) * 100,
        order_lower_limit: Number(payload.order_lower_limit) * 100,
        order_higher_limit: Number(payload.order_higher_limit) * 100,
      });

      if (status === 200) {
        return {
          success: true,
          response,
        };
      }
    } catch ({ response }) {
      errorHandler(response);
    }

    return {
      success: false,
    };
  },
  async placeOrder(payload) {
    try {
      const { status, data: response } = await server.post("/p2p_dex/orders/", {
        ...payload,
        amount: Number(payload.amount),
      });

      if (status === 200) {
        return {
          success: true,
          response,
        };
      }
    } catch ({ response }) {
      errorHandler(response);
    }

    return {
      success: false,
    };
  },
};
