<template>
  <mobile v-if="isMobile" />
  <desktop v-else />
</template>

<script>
import { mapGetters } from "vuex";
import Mobile from "./mobile.vue";
import Desktop from "./desktop.vue";

export default {
  components: {
    Mobile,
    Desktop,
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
};
</script>

<style lang="scss" scoped></style>
