var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      },
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.$emit('click');
      }
    }
  }, [_c('div', {
    staticClass: "icon"
  }, [_vm._t("icon", function () {
    return [_c('div', {
      staticClass: "plus"
    }, [_c('svg', {
      attrs: {
        "viewBox": "0 0 11.86 11.86",
        "xmlns": "http://www.w3.org/2000/svg"
      }
    }, [_c('g', {
      attrs: {
        "id": "Icon_feather-plus",
        "data-name": "Icon feather-plus"
      }
    }, [_c('path', {
      attrs: {
        "d": "M5.93,1v9.86",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2"
      }
    }), _c('path', {
      attrs: {
        "d": "M1,5.93h9.86",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2"
      }
    })])])])];
  })], 2), _vm._v(" " + _vm._s(_vm.text) + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }