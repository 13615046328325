var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('img', _vm._b({
    attrs: {
      "alt": `Icon for ${_vm.asset}`,
      "src": `https://raw.githubusercontent.com/crypti/cryptocurrencies/master/images/${_vm.asset}.png`
    },
    on: {
      "error": function ($event) {
        return _vm.reloadImage($event, _vm.asset);
      }
    }
  }, 'img', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }