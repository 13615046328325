var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "transaction-history"
  }, [_c('div', {
    staticClass: "header-wrapper"
  }, [_c('section-header', {
    attrs: {
      "title": "Assets"
    }
  }), _vm.isMobile ? _c('button', {
    on: {
      "click": function ($event) {
        _vm.showSearchBar = !_vm.showSearchBar;
      }
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.121",
      "height": "17.121",
      "viewBox": "0 0 17.121 17.121"
    }
  }, [_c('g', {
    attrs: {
      "id": "Icon_feather-search",
      "data-name": "Icon feather-search",
      "transform": "translate(1 1)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_2025",
      "data-name": "Path 2025",
      "d": "M17.573,11.036A6.536,6.536,0,1,1,11.036,4.5a6.536,6.536,0,0,1,6.536,6.536Z",
      "transform": "translate(-4.5 -4.5)",
      "fill": "#fff",
      "stroke": "#855AAF",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "id": "Path_2026",
      "data-name": "Path 2026",
      "d": "M28.529,28.529l-3.554-3.554",
      "transform": "translate(-13.822 -13.822)",
      "stroke": "#855AAF",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2"
    }
  })])]), _vm._v(" Search ")]) : _vm._e()], 1), _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [!_vm.isMobile || _vm.showSearchBar ? _c('div', {
    staticClass: "search-bar"
  }, [_c('i', [_c('img', {
    attrs: {
      "src": require("@/assets/icons/feather-search.svg"),
      "alt": "magnifying glass"
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.query,
      expression: "query"
    }],
    attrs: {
      "type": "search",
      "placeholder": "Search token"
    },
    domProps: {
      "value": _vm.query
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.query = $event.target.value;
      }
    }
  })]) : _vm._e()]), false ? _c('div', {
    staticClass: "transactions-empty"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/transaction.svg"),
      "alt": ""
    }
  }), _c('h3', [_vm._v("Nothing to see here")]), _c('p', [_vm._v("You’re yet to perform a transaction.")])]) : _c('ul', {
    staticClass: "transactions"
  }, [_vm.fetching ? _vm._l(8, function (n) {
    return _c('li', {
      key: n
    }, [_c('transaction-skeleton')], 1);
  }) : void 0], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }