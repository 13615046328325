import Vue from "vue";
import Vuex from "vuex";
import * as modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  state: () => ({
    isMobile: window.innerWidth < 1200,
  }),
  mutations: {
    SET_IS_MOBILE(state, isMobile) {
      state.isMobile = isMobile;
    },
  },
  actions: {
    checkScreenSize({ commit }) {
      window.addEventListener(
        "resize",
        () => {
          commit("SET_IS_MOBILE", window.innerWidth < 1200);
        },
        { passive: true }
      );
    },
  },
  getters: {
    isMobile(state) {
      return state.isMobile;
    },
  },
  modules: modules,
});
