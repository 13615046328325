<template>
  <div :aria-expanded="show" class="dropdown">
    <button
      :class="{ open: show }"
      class="dropdown-selected"
      role="button"
      @click="show = !show"
    >
      <span v-if="showCircle" class="circle"></span>
      <p>{{ selectedDisplay }}</p>

      <i class="icon">
        <img alt="" src="@/assets/icons/feather-arrow-down.svg" />
      </i>
    </button>
    <transition mode="out-in" name="fade-in">
      <ul v-show="show" :aria-expanded="show" class="dropdown-options">
        <li
          v-for="option in options"
          :key="option.option"
          :class="{
            active: $options.filters.formatOption(option) === value,
          }"
          class="dropdown-option"
          role="listbox"
          tabindex="0"
          @click="setActive(option)"
          @keypress.enter="setActive(option)"
        >
          <span class="checkbox" role="checkbox"></span>
          <p>
            {{ option | formatOption }}
          </p>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    value: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    "show-circle": {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    formatOption(option) {
      return option?.title ?? option?.option ?? option;
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    selectedDisplay() {
      const option = this.options.find((option) => {
        return (option?.option ?? option) === this.value;
      });
      return option?.title ?? option?.option ?? option;
    },
  },
  methods: {
    setActive(option) {
      this.$emit("input", option.option ?? option);
      setTimeout(this.close, 200);
    },
    close() {
      this.show = false;
    },
    async handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.close();
      }
    },
  },
  mounted() {
    window.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  font-weight: 500;
  position: relative;
  z-index: 99;

  &-selected {
    font-size: 1.2rem;
    color: black;
    display: flex;
    align-items: center;
    background: none;
    max-width: 185px;

    .circle {
      width: 10px;
      height: 10px;
      background: #f23636;
      border-radius: 50%;
      border: 2px solid #000000;
      margin-right: 6px;
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .icon {
      margin-left: 5px;
      transition: 0.5s all ease-out;
    }

    &.open {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  &-options {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    border: 2px solid black;
    border-radius: 8px;
    padding: 8px 0;
    font-size: 1.4rem;
    background: white;
    /* min-width: 100px; */
    white-space: nowrap;
  }

  &-option {
    padding: 8px 19px;
    display: grid;
    grid-template-columns: 20px auto;
    grid-gap: 16px;
    cursor: pointer;
    width: 100%;

    .checkbox {
      height: 20px;
      border-radius: 50%;
      border: 2px solid black;
      transition: 0.3s background ease-out;
    }

    &.active {
      .checkbox {
        background: {
          color: map-get($colours, purple);
          image: url(@/assets/icons/tick.svg);
          repeat: no-repeat;
          position: center;
          size: 60%;
        }
      }
    }

    &:hover,
    &:focus {
      background: rgba($color: map-get($colours, purple), $alpha: 0.1);
      outline: 2px solid black;
      border-radius: 8px;
    }
  }
}
</style>
