<template>
  <div class="features" :class="{ mobile: isMobile }">
    <feature-button
      v-for="(f, index) in features"
      :key="index"
      :background="f.background"
      :title="f.title"
      @click="f.onTap ? f.onTap() : null"
    >
      <template #icon>
        <img :src="f.src" :alt="f.alt" />
      </template>
    </feature-button>
  </div>
</template>

<script>
import { FeatureButton } from "@/components";
import { mapGetters } from "vuex";

export default {
  name: "CryptoFeatures",
  components: {
    FeatureButton,
  },
  data() {
    return {
      features: [
        {
          background: "#4187FF",
          title: "Trade (P2P)",
          src: require("@/assets/icons/arrow-upward-left.svg"),
          alt: "arrow pointing upwards to the left",
          onTap: () => {
            this.$router.push({
              name: "TradeP2P",
            });
          },
        },
        {
          background: "#909090",
          title: "Swap",
          src: require("@/assets/icons/arrow-upward-left.svg"),
          alt: "arrow pointing upwards to the left",
          onTap: () => {
            this.$toast.info(
              "This feature is coming soon to web and only available on the mobile app."
            );
          },
        },
        {
          background: "#FFC400",
          title: "Buy Airtime",
          src: require("@/assets/icons/phone.svg"),
          alt: "phone icon",
          onTap: () => {
            this.$router.push({ name: "BuyAirtime" });
          },
        },
        {
          background: "#855AAF",
          title: "Pay Bills",
          src: require("@/assets/icons/phone.svg"),
          alt: "phone icon",
          onTap: () => {
            this.$router.push({ name: "PayBills" });
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
};
</script>

<style lang="scss" scoped>
.features {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: min-content;
  grid-gap: 30px;
  padding: 35px 28px;

  &.mobile {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}
</style>
