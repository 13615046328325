<template>
  <div class="features" :class="{ mobile: isMobile }">
    <feature-button
      v-for="(f, index) in features"
      :key="index"
      :background="f.background"
      :title="f.title"
      @click="f.onTap ? f.onTap() : null"
    >
      <template #icon>
        <img :src="f.src" :alt="f.alt" />
      </template>
    </feature-button>
  </div>
</template>

<script>
import { FeatureButton } from "@/components";
import { mapGetters } from "vuex";

export default {
  name: "CryptoFeatures",
  components: {
    FeatureButton,
  },
  data() {
    return {
      features: [
        {
          background: "#FFC400",
          title: "Buy",
          src: require("@/assets/icons/arrow-downward-left.svg"),
          alt: "arrow pointing downwards to the left",
          onTap: () => {
            this.$toast.info("Buying Crypto is currently not available.");
            // this.$router.push({
            //   name: "TransactToken",
            //   params: {
            //     transaction: "buy",
            //   },
            // });
          },
        },
        {
          background: "#4187FF",
          title: "Withdraw",
          src: require("@/assets/icons/arrow-upward-left.svg"),
          alt: "arrow pointing upwards to the left",
          onTap: () => {
            this.$router.push({
              name: "TransactToken",
              params: {
                transaction: "sell",
              },
            });
          },
        },
        {
          background: "#855AAF",
          title: "Send",
          src: require("@/assets/icons/send.svg"),
          alt: "airplane icon",
          onTap: () => {
            this.$router.push({
              name: "TransactToken",
              params: {
                transaction: "send",
              },
            });
          },
        },
        {
          background: "#FC6190",
          title: "Deposit",
          src: require("@/assets/icons/qrcode.svg"),
          alt: "grid icon",
          onTap: () => {
            this.$router.push({
              name: "DepositToken",
            });
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
};
</script>

<style lang="scss" scoped>
.features {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: min-content;
  grid-gap: 30px;
  padding: 35px 28px;

  &.mobile {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}
</style>
