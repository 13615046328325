var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section-header"
  }, [_c('h2', [_vm._v(_vm._s(_vm.title))]), _vm.action ? _c('button', {
    on: {
      "click": _vm.onTap
    }
  }, [_vm._v(_vm._s(_vm.action))]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }