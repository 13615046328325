<template>
  <div v-if="!$store.getters.showEmptyState" id="recent-beneficiaries">
    <section-header title="Recent Beneficiaries" action="See All" />

    <div class="beneficiaries-grid">
      <crypto-beneficiary
        v-for="beneficiary in beneficiaries"
        :key="beneficiary.address"
        v-bind="beneficiary"
      />
    </div>
  </div>
</template>

<script>
import CryptoBeneficiary from "./beneficiary.vue";
import { SectionHeader } from "@/components";
export default {
  name: "CryptoBeneficiaries",
  components: {
    CryptoBeneficiary,
    SectionHeader,
  },
  data() {
    return {
      beneficiaries: [
        {
          avatar: "https://avatars.dicebear.com/api/avataaars/AlienX.svg",
          name: "AlienX",
          address: "@el",
        },
        {
          avatar: null,
          name: "Ogbons",
          address: "0248562",
        },
        {
          avatar: null,
          name: "King Hermy",
          address: "@Hammer",
        },
        {
          avatar: "https://avatars.dicebear.com/api/avataaars/WarChief.svg",
          name: "WarChief",
          address: "@ShinraT",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#recent-beneficiaries {
  border-top: 1px solid black;
  padding: 24px 28px;

  .beneficiaries-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 15.8px;
  }
}
</style>
