<template>
  <div class="section-header">
    <h2>{{ title }}</h2>
    <button v-if="action" @click="onTap">{{ action }}</button>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
    action: {
      type: String,
    },
    "on-tap": {
      type: Function,
      default() {
        return function () {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;

  h2 {
    font-size: 1.6rem;
    letter-spacing: unset;
  }

  button {
    font-size: 1.4rem;
    color: map-get($map: $colours, $key: purple);
    background: none;
  }
}
</style>
