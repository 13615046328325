import { errorHandler, server } from "@/utils";
import toast from "vue-m-message";

export default {
  async get() {
    try {
      const { status, data: response } = await server.get(
        "/beneficiaries/list/"
      );

      if (status === 200) {
        return response;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async getBanks() {
    try {
      const { status, data: response } = await server.get("/banks/ngn/list/");

      if (status === 200) {
        return response;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async getCrypto() {
    try {
      const { status, data: response } = await server.get(
        "/beneficiaries/crypto_wallet/addresses/"
      );

      if (status === 200) {
        return response;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async addFlux({ receiver, name }) {
    try {
      const { status, data: response } = await server.post(
        "/beneficiaries/add/",
        {
          receiver,
          name,
        }
      );

      if (status === 200) {
        toast.success(response.msg);
        return response;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async addBank({ bank_code, bank_name, account_no, acct_name }) {
    try {
      const { status, data: response } = await server.post("/banks/ngn/add/", {
        bank_code,
        bank_name,
        account_no,
        acct_name,
      });

      if (status === 200) {
        toast.success(response.msg);
        return response;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
  async addCrypto({ asset, network, address, custom_name }) {
    try {
      const { status, data: response } = await server.post(
        "/beneficiaries/crypto_wallet/addresses/",
        {
          asset,
          network,
          address,
          custom_name,
        }
      );

      if (status === 200) {
        toast.success(response.msg);
        return response;
      }
    } catch ({ response }) {
      errorHandler(response);
    }
  },
};
