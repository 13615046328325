var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-select",
    attrs: {
      "aria-expanded": _vm.show
    },
    on: {
      "click": function ($event) {
        _vm.show = !_vm.show;
      }
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._t("selected", function () {
    return [_c('input', {
      ref: "input",
      attrs: {
        "placeholder": _vm.placeholder,
        "disabled": ""
      },
      domProps: {
        "value": _vm.displayTitle
      }
    })];
  }), _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.label))])], 2), _vm._m(0), _c('transition', {
    attrs: {
      "mode": "out-in",
      "name": "fade-in"
    }
  }, [_c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "select-options",
    style: {
      maxHeight: _vm.maxHeight
    },
    attrs: {
      "aria-expanded": _vm.show
    }
  }, _vm._l(_vm.options, function (option, index) {
    return _c('li', {
      key: index,
      staticClass: "select-option",
      class: {
        active: _vm.getOption(option) === _vm.value
      },
      attrs: {
        "role": "listbox",
        "tabindex": "0"
      },
      on: {
        "click": function ($event) {
          return _vm.setActive(option);
        },
        "keypress": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return _vm.setActive(option);
        }
      }
    }, [_c('span', {
      staticClass: "checkbox",
      attrs: {
        "role": "checkbox"
      }
    }), _vm._t("default", function () {
      return [_c('p', [_vm._v(" " + _vm._s(_vm.getTitle(option)) + " ")])];
    }, {
      "option": option
    })], 2);
  }), 0)])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('i', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "alt": "",
      "src": require("@/assets/icons/feather-arrow-down.svg")
    }
  })]);

}]

export { render, staticRenderFns }