import { Crypto } from "@/api";

export default {
  namespaced: true,
  state: () => ({
    activeCoin: null,
    tokens: [],
    rates: {},
    ratesInterval: null,
  }),
  mutations: {
    SET_TOKENS(state, tokens) {
      state.tokens = tokens;
    },
    SET_RATES(state, rates) {
      state.rates = rates;
    },
    SET_ACTIVE(state, coin) {
      state.activeCoin = coin;
    },
    SET_RATES_INTERVAL(state, timer) {
      state.ratesInterval = timer;
    },
  },
  actions: {
    async getTokens({ commit }) {
      const tokens = await Crypto.getWallets();
      commit("SET_TOKENS", tokens);
      return tokens;
    },
    async getToken({ getters, dispatch }, ref) {
      const token = getters.tokens.find((token) => token.ref === ref);
      if (token) return token;
      else {
        const tokens = await dispatch("getTokens");
        const token = tokens.find((token) => token.ref === ref);
        if (token) return token;
      }
    },
    async getRates({ commit }) {
      const rates = await Crypto.getRates();
      console.log(rates, 'lojay')
      const toObject = (arr) => {
        const rv = {};
        for (let i = 0; i < arr?.length; ++i) {
          rv[arr[i].sender_currency] = arr[i].amount;
        }
        return rv;
      };
      commit("SET_RATES", toObject(rates));
    },
    async getRatesContinous({ dispatch, state, commit }) {
      await dispatch("getRates");

      if (state.timer) clearInterval(state.timer);

      const timer = setInterval(() => dispatch("getRates"), 30 * 1000);
      commit("SET_RATES_INTERVAL", timer);
    },
  },
  getters: {
    tokens(state) {
      return state.tokens.map((token) => {
        return {
          ...token,
          converted_balance: token.balance * state.rates[token.asset],
        };
      });
    },
    allowedTokens(state, getters) {
      const allowedCryptos = ["BTC", "USDT", "ETH", "BNB", "BUSD", "XLM"];
      return getters.tokens.filter((token) =>
        allowedCryptos.includes(token.asset)
      );
    },
    rates(state) {
      return state.rates;
    },
    activeCoin(state) {
      return state.activeCoin;
    },
  },
};
