<template>
  <div class="transaction-history">
    <!-- <section-header
      title="Transaction History"
      :action="showEmptyState ? null : 'See All'"
    /> -->
    <section-header title="Transaction History" />

    <div v-if="!loading && showEmptyState" class="transactions-empty">
      <img src="@/assets/icons/transaction.svg" alt="" />
      <h3>Nothing to see here</h3>
      <p>You’re yet to perform a transaction.</p>
    </div>
    <ul v-else class="transactions">
      <template v-if="loading">
        <transaction-skeleton v-for="n in 5" :key="n" />
      </template>
      <template v-else>
        <transaction-card
          v-for="(transaction, index) in transactions"
          :key="index"
          v-bind="transaction"
          :status="transaction.status"
          :action="transaction.action | toUpperCase"
          :image="transaction.sender_pic"
          :date="transaction.updated_at | toFormattedDate"
          :amount="assignPolarity(transaction)"
        />
      </template>
    </ul>
  </div>
</template>

<script>
import { Transactions } from "@/api";
import { transactionTypes } from "@/constants";
import { SectionHeader } from "@/components";
import TransactionCard from "./transaction-card.vue";
import TransactionSkeleton from "./transaction-skeleton.vue";
export default {
  name: "WalletHistory",
  props: {
    height: {
      type: String,
      default: "50%",
    },
  },
  components: {
    SectionHeader,
    TransactionCard,
    TransactionSkeleton,
  },
  data() {
    return {
      loading: true,
      transactions: [],
    };
  },
  computed: {
    showEmptyState() {
      return this.transactions.length === 0;
    },
  },
  methods: {
    async getHistory() {
      this.loading = true;
      const response = await Transactions.get();
      if (response) {
        this.transactions = response;
      }
      this.loading = false;
    },
    assignPolarity(transaction) {
      let suffix;
      suffix = transactionTypes.credits.includes(transaction.action)
        ? "+"
        : "-";

      return (
        suffix +
        this.$root.$options.filters.toCurrency(
          transaction.amount,
          transaction.currency
        )
      );
    },
  },
  async mounted() {
    this.$el.style.setProperty("--height", this.height);
    this.getHistory();
  },
};
</script>

<style lang="scss" scoped>
.transaction-history {
  padding: 14px 28px;
  height: var(--height);
  overflow: hidden;
  position: relative;

  .transactions {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    height: 100%;
    overflow: auto;
    @include hide-scrollbar();
    padding-bottom: 100px;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    background-image: linear-gradient(to bottom, transparent, #fff);
  }

  .transactions-empty {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    img {
      margin-bottom: 24px;
    }

    h3 {
      font-size: 1.6rem;
    }

    p {
      margin-top: 4px;
      font-size: 1.4rem;
    }
  }
}
</style>
