import store from "@/store";

export default [
  {
    path: "/auth",
    redirect: "/auth/login",
  },
  {
    path: "/auth/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/Auth/Login"),
  },
  {
    path: "/auth/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/Auth/Login"),
  },
  {
    path: "/auth/signup",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/Auth/Signup"),
    children: [
      {
        path: "",
        name: "Signup",
        components: {
          signup: () =>
            import(
              /* webpackChunkName: "auth" */ "@/views/Auth/Signup/name"
            ),
        },
        meta: {
          title: "Enter your full name and prefered username",
        },
      },
      {
        path: "email",
        name: "SignupEmail",
        components: {
          signup: () =>
            import(
              /* webpackChunkName: "auth" */ "@/views/Auth/Signup/email"
            ),
        },
        meta: {
          title: "Enter your email address.",
        },
        beforeEnter(to, from, next) {
          if (store.getters["user/signUpDetails"].fullname) next();
          else next({ name: "Signup" });
        },
      },
      {
        path: "password",
        name: "SignupPassword",
        components: {
          signup: () =>
            import(
              /* webpackChunkName: "auth" */ "@/views/Auth/Signup/password"
            ),
        },
        meta: {
          title: "Set a password for your account",
        },
        beforeEnter(to, from, next) {
          if (store.getters["user/signUpDetails"].email) next();
          else next({ name: "SignupEmail" });
        },
      },
      {
        path: "phone",
        name: "SignupPhone",
        components: {
          signup: () =>
            import(
              /* webpackChunkName: "auth" */ "@/views/Auth/Signup/phone"
            ),
        },
        meta: {
          title: "Enter phone number",
        },
        beforeEnter(to, from, next) {
          if (
            store.getters["user/signUpDetails"].password1 ||
            store.getters["user/user"].phone
          )
            next();
          else next({ name: "SignupPassword" });
        },
      },
      {
        path: "phone/verify",
        name: "SignupPhoneVerify",
        components: {
          signup: () =>
            import(
              /* webpackChunkName: "auth" */ "@/views/Auth/Signup/phone/verify"
            ),
        },
        meta: {
          title: "Verify phone number",
        },
        beforeEnter(to, from, next) {
          if (
            store.getters["user/signUpDetails"].phone ||
            store.getters["user/user"].phone
          )
            next();
          else next({ name: "SignupPhone" });
        },
      },
      {
        path: "bvn",
        name: "SignupBVN",
        components: {
          signup: () =>
            import(
              /* webpackChunkName: "auth" */ "@/views/Auth/Signup/bvn"
            ),
        },
        meta: {
          title: "Enter your BVN",
        },
        beforeEnter(to, from, next) {
          if (
            store.getters["user/signUpDetails"].phone ||
            store.getters["user/user"].phone
          )
            next();
          else next({ name: "SignupPhone" });
        },
      },
      {
        path: "bvn/verify",
        name: "SignupBVNVerify",
        components: {
          signup: () =>
            import(
              /* webpackChunkName: "auth" */ "@/views/Auth/Signup/bvn/verify"
            ),
        },
        meta: {
          title: "Verify BVN",
        },
        beforeEnter(to, from, next) {
          if (
            store.getters["user/signUpDetails"].bvn ||
            store.getters["user/user"].bvn
          )
            next();
          else next({ name: "SignupBVN" });
        },
      },
    ],
  },
];
