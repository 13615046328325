var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "balance"
  }, [_c('ul', {
    staticClass: "balance-heading"
  }, _vm._l(_vm.options, function (option) {
    return _c('li', {
      key: option.title,
      class: {
        active: _vm.currency === option.option
      },
      attrs: {
        "role": "button"
      },
      on: {
        "click": function ($event) {
          _vm.currency = option.option;
        }
      }
    }, [_vm._v(" " + _vm._s(option.title) + " ")]);
  }), 0), _c('div', {
    staticClass: "balance-available"
  }, [_c('div', {
    staticClass: "div"
  }, [_c('h2', [!_vm.hidden ? [_vm._v(" " + _vm._s(_vm._f("toCurrency")(_vm.balance.available_balance, _vm.currency)) + " ")] : [_vm._v(" ******"), _c('span', [_vm._v(".**")])]], 2)]), _c('button', {
    staticClass: "icon",
    on: {
      "click": function ($event) {
        _vm.hidden = !_vm.hidden;
      }
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.hidden ? _c('img', {
    key: _vm.hidden.toString(),
    attrs: {
      "src": require("@/assets/icons/eye-open.svg"),
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/icons/eye-closed.svg"),
      "alt": ""
    }
  })])], 1)]), _c('div', {
    staticClass: "balance-locked"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/icons/feather-lock.svg"),
      "alt": "A padlock icon"
    }
  }), _c('p', [!_vm.hidden ? [_vm._v(" " + _vm._s(_vm._f("toCurrency")(_vm.balance.ledger_balance || 0, _vm.currency)) + " ")] : [_vm._v(" ******"), _c('span', [_vm._v(".**")])]], 2)]), _c('ul', {
    staticClass: "balance-actions"
  }, _vm._l(_vm.actions, function (action, index) {
    return _c('li', {
      key: index,
      staticClass: "action",
      attrs: {
        "role": "button"
      },
      on: {
        "click": function ($event) {
          action.onTap ? action.onTap() : null;
        }
      }
    }, [_c('i', {
      staticClass: "icon",
      style: {
        backgroundColor: action.background
      }
    }, [action.src ? _c('img', {
      attrs: {
        "src": action.src,
        "alt": action
      }
    }) : _vm._e()]), _c('p', [_vm._v(_vm._s(action.title))])]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }