import { Cards } from "@/api";

export default {
  namespaced: true,
  state: () => ({
    cards: [],
  }),
  mutations: {
    SET_CARDS(state, cards) {
      state.cards = cards;
    },
  },
  actions: {
    async getCards({ commit }) {
      try {
        const cards = await Cards.get();
        commit("SET_CARDS", cards);
        return cards;
      } catch (err) {
        // console.log(err);
      }
    },
    async getCard({ getters, dispatch }, ref) {
      const card = getters.cards.find((card) => card.ref === ref);
      if (card) return card;
      else {
        const cards = await dispatch("getCards");
        const card = cards.find((card) => card.ref === ref);
        if (card) return card;
      }
    },
  },
  getters: {
    cards(state) {
      return state.cards;
    },
  },
};
