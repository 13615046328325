<template>
  <div class="virtual-cards">
    <template v-if="!showEmptyState">
      <section-header
        title="Virtual Card"
        action="More Actions"
        :onTap="moreActions"
      />
      <cards-slide v-model="index" :cards="cards" />
      <ul class="actions">
        <li
          v-for="(action, index) in actions"
          :key="index"
          class="action"
          role="button"
          @click="action.onTap ? action.onTap() : null"
        >
          <i class="icon">
            <img v-if="action.icon" :src="action.icon" :alt="action" />
          </i>
          <p>{{ action.title }}</p>
        </li>
      </ul>
    </template>
    <template v-else>
      <div class="empty">
        <img
          src="@/assets/images/cards/empty_state.png"
          alt="Flux card designs"
        />
        <h3>Flux Virtual Cards</h3>
        <p>Shop anywhere online easily and securely.</p>
        <text-button text="Create Virtual Card" @click="createCard" />
      </div>
    </template>
  </div>
</template>

<script>
import { SectionHeader, TextButton } from "@/components";
import CardsSlide from "./cards.vue";
import { mapGetters } from "vuex";
export default {
  name: "WalletCards",
  props: {
    height: {
      type: String,
      default: "50%",
    },
  },
  components: {
    SectionHeader,
    CardsSlide,
    TextButton,
  },
  data() {
    return {
      actions: [
        {
          title: "Top up Card",
          icon: require("@/assets/icons/plus.svg"),
          onTap: () => {
            this.$router.push({
              name: "CardTopup",
              params: {
                card_id: this.activeCard.ref,
              },
            });
          },
        },
        {
          title: "View Details",
          icon: require("@/assets/icons/eye-closed.svg"),
          onTap: () => {
            this.$router.push({
              name: "CardDetails",
              params: {
                card_id: this.activeCard.ref,
              },
            });
          },
        },
        {
          title: "Withdraw",
          icon: require("@/assets/icons/arrow-upward-left.svg"),
        },
      ],
      index: 0,
    };
  },
  computed: {
    ...mapGetters({ cards: "cards/cards" }),
    showEmptyState() {
      return this.cards.length === 0;
    },
    activeCard() {
      return this.cards[this.index];
    },
  },
  mounted() {
    this.$el.style.setProperty("--height", this.height);
    this.$store.dispatch("cards/getCards");
  },
  methods: {
    moreActions() {
      this.$router.push({
        name: "CardActions",
        params: {
          card_id: this.activeCard.ref,
        },
      });
    },
    createCard() {
      this.$router.push({
        name: "CreateCard",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.virtual-cards {
  padding: 14px 28px 28px;
  height: var(--height);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  .actions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: auto;
    width: 100%;

    .action {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 2px solid #000;
      background: map-get($colours, purple);
      display: grid;
      place-items: center;
      margin-bottom: 8px;

      img {
        max-width: 40%;
        max-height: 40%;
        filter: brightness(0);
      }
    }
  }

  .empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      max-height: 120px;
      margin-bottom: 24px;
    }

    h3 {
      font-size: 1.6rem;
      margin-bottom: 4px;
    }

    p {
      font-size: 1.4rem;
      margin-bottom: 26px;
    }
  }
}
</style>
