var render = function render(){
  var _vm$user, _vm$user$full_name;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    class: {
      mobile: _vm.isMobile,
      scrolled: _vm.scrolled && _vm.isMobile
    },
    attrs: {
      "id": "profile-bar"
    }
  }, [_vm.user ? _c('div', {
    staticClass: "user"
  }, [_c('div', {
    staticClass: "user-avatar",
    attrs: {
      "role": "button"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.user.pic,
      "alt": ""
    },
    on: {
      "click": _vm.toggleDrawer
    }
  })]), _c('p', {
    staticClass: "user-name"
  }, [_vm._v(" Hi "), _c('strong', [_vm._v(_vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : (_vm$user$full_name = _vm$user.full_name) === null || _vm$user$full_name === void 0 ? void 0 : _vm$user$full_name.split(" ")[0]))])])]) : _vm._e(), _vm.isWeb3 ? [_c('div', {
    staticClass: "wallet__container"
  }, [_c('div', {
    staticClass: "wallet__info--container"
  }, [_c('span', {
    staticClass: "wallet__info--network"
  }, [_vm._v(" " + _vm._s(_vm.network) + " ")]), _c('span', {
    staticClass: "wallet__info--address"
  }, [_vm._v(_vm._s(_vm.truncateText(_vm.address.address)))])]), !_vm.isFreighterConnected ? _c('button', {
    on: {
      "click": _vm.disconnect
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/bin.svg"),
      "alt": "disconnect wallet",
      "title": "Disconnect wallet"
    }
  })]) : _vm._e()])] : [_c('div', {
    staticClass: "flux"
  }, [_c('span', {
    staticClass: "flux-head"
  }, [_vm._v("Flux ID and Tag")]), _c('div', {
    staticClass: "flux-details",
    attrs: {
      "title": "Click to copy to Clipboard"
    }
  }, [_c('p', {
    staticClass: "flux-id",
    attrs: {
      "role": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.copy(_vm.user.flux_id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.user.flux_id) + " ")]), _c('span', {
    staticClass: "separator"
  }), _vm.user.flux_tag ? _c('p', {
    staticClass: "flux-tag",
    attrs: {
      "role": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.copy(_vm.user.flux_tag);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.user.flux_tag) + " ")]) : _vm._e()])])], _vm.isMobile ? _c('transition', {
    attrs: {
      "name": "slide-out"
    }
  }, [_vm.showDrawer ? _c('aside', {
    class: {
      mobile: _vm.isMobile
    },
    attrs: {
      "id": "drawer"
    }
  }, [_c('div', {
    staticClass: "drawer-body"
  }, [_c('drawer-header', {
    attrs: {
      "defaultAction": "close",
      "onTap": _vm.toggleDrawer
    }
  }), _c('div', {
    staticClass: "details"
  }, [_c('div', {
    staticClass: "user"
  }, [_c('div', {
    staticClass: "user-avatar",
    attrs: {
      "role": "button"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.user.pic,
      "alt": ""
    }
  })]), _c('div', [_c('p', {
    staticClass: "user-name"
  }, [_c('strong', [_vm._v(_vm._s(_vm.user.full_name))])]), _c('div', {
    staticClass: "flux-details"
  }, [_c('p', {
    staticClass: "flux-id"
  }, [_vm._v(_vm._s(_vm.user.flux_id))]), _c('span', {
    staticClass: "separator"
  }), _vm.user.flux_tag ? _c('p', {
    staticClass: "flux-tag"
  }, [_vm._v(" " + _vm._s(_vm.user.flux_tag) + " ")]) : _vm._e()])])])]), _c('ul', {
    staticClass: "drawer-actions"
  }, _vm._l(_vm.actions, function (action) {
    return _c('li', {
      key: action.title,
      staticClass: "drawer-action",
      attrs: {
        "role": "button"
      },
      on: {
        "click": function ($event) {
          action.onTap ? action.onTap() : null;
        }
      }
    }, [_c('img', {
      attrs: {
        "src": action.src,
        "alt": action.alt
      }
    }), _c('p', [_vm._v(_vm._s(action.title))])]);
  }), 0)], 1)]) : _vm._e()]) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }