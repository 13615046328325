var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "slide-up"
    }
  }, [_vm.show ? _c('aside', {
    ref: "modalSheet",
    attrs: {
      "id": "modal-sheet"
    }
  }, [_c('div', {
    staticClass: "modal-sheet-body"
  }, [_c('div', {
    staticClass: "indicator"
  }), _vm.title ? _c('div', {
    staticClass: "header"
  }, [_c('h3', [_vm._v(_vm._s(_vm.title))]), _c('button', {
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "alt": "",
      "src": require("@/assets/icons/plus.svg")
    }
  })])]) : _vm._e(), _vm._t("default")], 2)]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }