<template>
  <img
    :alt="`Icon for ${asset}`"
    :src="`https://raw.githubusercontent.com/crypti/cryptocurrencies/master/images/${asset}.png`"
    v-bind="$attrs"
    @error="reloadImage($event, asset)"
  />
</template>

<script>
export default {
  props: {
    asset: {
      type: String,
      required: true,
    },
  },
  methods: {
    reloadImage(event, asset) {
      const replacement = `https://raw.githubusercontent.com/theGrandmaster05/cryptocurrency-icons/master/32/${asset.toLowerCase()}.png`;
      if (event.target.src !== replacement) {
        event.target.src = replacement;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
