var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.$store.getters.showEmptyState ? _c('div', {
    attrs: {
      "id": "recent-beneficiaries"
    }
  }, [_c('section-header', {
    attrs: {
      "title": "Recent Beneficiaries",
      "action": "See All"
    }
  }), _c('div', {
    staticClass: "beneficiaries-grid"
  }, _vm._l(_vm.beneficiaries, function (beneficiary) {
    return _c('crypto-beneficiary', _vm._b({
      key: beneficiary.address
    }, 'crypto-beneficiary', beneficiary, false));
  }), 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }